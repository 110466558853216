/* 布局 */
import Layout from "@views/layout";

const CommonRouter = {
    path: "/deal",
    component: Layout,
    redirect: {
        name: "DEAL"
    },
    children: [
        {
            path: "common",
            name: "COMMON",
            meta: {
                title: "",
                sort: 1,
                keepAlive: false
            },
            component: () => import("@views/common/index.vue"),
            redirect: {
                name: "COMMON_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "COMMON_LIST",
                    meta: {
                        title: "",
                        sort: 2,
                        keepAlive: false
                    },
                    component: () => import("@views/common/list.vue"),
                },
                {
                    path: "add",
                    name: "COMMON_ADD",
                    meta: {
                        title: "",
                        parentPath: '/deal/common/list',
                        operate: true,
                    },
                    component: () => import("@views/common/form.vue"),
                },
                {
                    path: "edit",
                    name: "COMMON_EDIT",
                    meta: {
                        title: "",
                        parentPath: '/deal/common/list',
                        operate: true,
                    },
                    component: () => import("@views/common/form.vue"),
                },
                {
                    path: "mylist",
                    name: "COMMON_MYLIST",
                    meta: {
                        title: "",
                    },
                    component: () => import("@views/common/list.vue"),
                },
            ],
        },


    ],
};

export default CommonRouter;
