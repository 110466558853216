<template>
    <div>
        <try-bounced id="attachment" :dialog-show.sync="dialogShow" width="950px" class="closeIcon" :title="title"
            :append-to-body="false" :disabled="disabled">
            <div class="attachment">
                <div class="left">
                    <el-input v-model="cateTitle" placeholder="请输入分类名称">
                        <el-button slot="append" type="primary" icon="el-icon-search"
                            style="border-radius: 3px; border: none" @click="serarchCate" />
                    </el-input>
                    <try-attachmentcate ref="AttachmentCate" @update="getList"></try-attachmentcate>
                </div>
                <div class="right">
                    <div class="flex">
                        <div>
                            <el-button :class="num > 0 ? 'dele' : ''" :disabled="num > 0 ? false : true" size="small"
                                style="margin-right: 10px" @click="dele">
                                删除附件
                            </el-button>
                            <el-badge :value="num" :hidden="num > 0 ? false : true">
                                <el-button :class="num > 0 ? 'orange' : ''" :type="num > 0 ? 'primary' : ''"
                                    :disabled="num > 0 ? false : true" size="small" @click="choose">
                                    使用选中附件
                                </el-button>
                            </el-badge>

                            <el-upload :headers="paramsData" class="attachment_upload" action="/api/tool/uploadimg/"
                                :show-file-list="false" :on-success="handleSuccess" :on-progress="onProgress"
                                :data="uploadData">
                                <el-button size="small" type="primary" @click="getTypeCate">
                                    上传附件
                                </el-button>
                            </el-upload>
                            <el-button size="small" type="primary" style="margin-right: 10px" @click="showDialog = true">
                                网络附件
                            </el-button>
                            <el-dialog id="attachment" :visible.sync="showDialog" width="950px" class="closeIcon"
                                title="添加网络附件" :modal="false" :append-to-body="true">
                                <el-form ref="urlForm" :model="urlForm" :rules="urlRules" label-width="60px">
                                    <el-form-item label="名称:" prop="att_title">
                                        <el-input v-model="urlForm.att_title" />
                                    </el-form-item>
                                    <el-form-item label="地址:" prop="att_url">
                                        <el-input v-model="urlForm.att_url" />
                                    </el-form-item>
                                    <el-form-item label="描述:">
                                        <el-input v-model="urlForm.att_description" type="textarea" />
                                    </el-form-item>
                                    <div class="btn">
                                        <el-button @click="showDialog = false">取消</el-button>
                                        <el-button type="primary" @click="uploadUrl">
                                            确定
                                        </el-button>
                                    </div>
                                </el-form>
                            </el-dialog>
                        </div>
                        <el-input v-model="name" placeholder="请输入附件名称" style="width: 200px">
                            <el-button slot="append" type="primary" icon="el-icon-search"
                                style="border-radius: 3px; border: none" @click="serarchAttachment" />
                        </el-input>
                    </div>

                    <div v-loading="loading" class="attachment_box">
                        <div v-for="item in orderData" :key="item.att_id" class="attachment_item">
                            <div v-if="attachmentType == 2" id="item" @click="handelChoose(item)">
                                <el-image :src="item.att_url" alt="" :class="currentClass(item.att_id)" />
                            </div>

                            <div v-else-if="attachmentType == 3" id="video" :class="currentClass(item.att_id)"
                                @click="handelChoose(item)">
                                <i class="el-icon-video-camera" />
                            </div>
                            <div v-else id="video" :class="currentClass(item.att_id)" @click="handelChoose(item)">
                                <i class="el-icon-tickets" />
                            </div>
                            <p class="attachment_name">{{ item.att_title }}</p>
                        </div>
                        <div class="attachment_item seat" />
                        <div class="attachment_item seat" />
                        <div class="attachment_item seat" />
                        <div class="attachment_item seat" />
                    </div>
                    <el-pagination :current-page="pagination.currentPage" :page-sizes="pagination.pageSizes"
                        :page-size="pagination.size" layout="total, prev, pager, next, jumper" :total="pagination.total"
                        :hide-on-single-page="pagination.total < pagination.size ? true : false"
                        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                </div>
            </div>
        </try-bounced>

    </div>
</template>

<script>
import {
    getAttachmentList,
    getAttachmentInfo,
    editAttachment,
    addAttachment,
    deleteAttachment,
} from "@api/Attachment";

import TryAttachmentcate from "../Attachmentcate/index";
import TryBounced from "../Bounced/index";

export default {
    name: 'TryAttachment',
    components: { TryBounced, TryAttachmentcate },
    props: {
        title: {
            type: String,
            default: '选择图片'
        },
        show: {
            type: Boolean,
            default: false
        },
        attachmentType: {
            default: 2
        },
        disabled: {//禁用属性
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            uploadData: {
                type: 2,
                attachment_cate_id: 0
            },
            paramsData: {
                "accessToken": sessionStorage.getItem("token"),
            }, // 上传携带的参数，看需求要不要
            showDialog: false,
            urlForm: {
                name: '',
                url: '',
                description: '',
                storage: 5
            },
            urlRules: {
                name: [{ required: 'true', message: '请输入附件名', trigger: 'blur' }],
                url: [{ required: 'true', message: '请输入附件地址', trigger: 'blur' }]
            },
            cateTitle: '',
            cateList: [], // 附件分类列表
            dialogShow: this.show,
            fullscreen: false,
            loading: false,
            num: 0,
            attachmentData: [],
            orderData: [],
            imagelist: [],
            myHeaders: { Authorization: 'Bearer ' },
            headers: {},
            logoUrl: '',
            wechatUrl: '',
            imgUrl: this.$store.getters.updateURL,
            pagination: {
                page: 1,
                total: 0, //总数
                current_page: 1, //当前页默认第一页
                size: 18, //每页数量
                sizes: [10, 18, 20, 25, 30], //分页模式
            }, //分页数据
            page: {
                total: 0,
                currentPage: 1,
                size: 18,
                pageSizes: [18]
            }, // 分页器
            currentNumber: [],
            currentItem: [],
            is_used: false,
            name: ''
        }
    },
    watch: {
        show: function (newQuestion, oldQuestion) {
            this.dialogShow = newQuestion
        },
        dialogShow: function (newQuestion, oldQuestion) {
            this.$emit('on-close', newQuestion);
        },
        attachmentType(val, value) {
            // console.log(val, value, '------------------')
        }
    },
    created() {
        this.getList();
    },
    activated() {
        this.getList();
    },
    mounted() {
        // console.log(this.show, '----------------')
        if (this.show) {
            this.getList()
        }
    },
    methods: {
        // 添加网络附件
        async uploadUrl() {

            /** 请求編輯接口*/
            var res;
            res = await addAttachment(this.urlForm);
            if (res.status != 200) {
                this.$message.error(res.msg)
                return;
            }
            this.$message({
                message: res.msg,
                type: "success"
            });
            this.showDialog = false
            this.getList()
            this.urlForm = {}


        },
        // 搜索附件分类
        serarchCate() {
            this.$refs.AttachmentCate.getCateList(this.cateTitle)
        },
        // 搜索附件
        serarchAttachment() {
            // console.log(this.$refs.AttachmentCate.cate_id)
            this.getList()
        },
        // 附件类别
        // getCateList() {
        //   attachmentApi.cateList().then(res => {
        //     if (res.code == 0) {
        //       this.cateList = res.data
        //     } else {
        //       this.$message.error(res.msg)
        //     }
        //   })
        // },
        // 删除图片
        dele() {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    attachmentApi
                        .attachmentDelete({ id: this.currentNumber })
                        .then(res => {
                            if (res.code == 0) {
                                this.$message.success(res.msg)
                                this.getAttachmentList()
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        },
        fullScreen() {
            this.fullscreen = !this.fullscreen
        },
        close() {
            console.log("fsd");
            this.$emit('on-close')
        },
        // 选中图片
        handelChoose(item) {
            console.log(item)
            // this.show = true
            const isIn = this.currentNumber.indexOf(item.att_id)
            if (isIn == -1) {
                this.currentNumber.push(item.att_id)
                this.currentItem.push(item)
            } else {
                this.currentNumber.splice(isIn, 1)
                this.currentItem.splice(isIn, 1)
            }
            this.num = this.currentNumber.length
        },
        currentClass(index) {
            const isIn = this.currentNumber.indexOf(index)
            // console.log(this.currentNumber, index, '----------------')
            if (isIn != -1) {
                return 'active'
            }
        },
        // 选择附件
        choose() {
            this.$emit('func', this.currentItem)
        },
        // 列表
        async getList(cate_id) {
            var params = {};
            params.website_id = this.$store.getters.siteId
            // console.log(cate_id != undefined, '=================')
            if (cate_id != undefined) {
                this.uploadData.attachment_cate_id = cate_id
            }
            params.cate_id = this.uploadData.attachment_cate_id
            if (this.name != '') {
                params.keyword = this.name
            }

            this.loading = true;
            const pages = {
                pageindex: this.pagination.current_page,
                pagesize: this.pagination.size,
            };
            var res;

            const {
                data: resdata
            } = await getAttachmentList(params, pages);
            res = resdata;

            this.orderData = res.data; //数据
            this.pagination.total = res.allcount; //总数
            this.pagination.current_page = res.pageindex; //当前页
            this.loading = false;


            this.list = []
            // 表格数据  drawerTableList
            // 获取每张图片的地址
            for (var x = 0; x < this.orderData.length; x++) {
                this.list.push(this.orderData[x].att_url)
            }

            // 根据图片顺序（index）更改每张图片绑定的list的图片顺序
            for (var i = 0; i < this.orderData.length; i++) {
                // this.list.push(this.drawerTableList[i].path)
                var container = this.list

                var frontArr = container.slice(0, i)
                var behindArr = container.slice(i, this.orderData.length)
                var list = behindArr.concat(frontArr)
                this.orderData[i].list = list
            }

            this.$forceUpdate();

        },
        // 点击上传
        getTypeCate() {
            this.uploadData.attachment_cate_id = this.$refs.AttachmentCate.cate_id
            // console.log(this.$refs.AttachmentCate.cate_id)
        },
        // 上传中
        onProgress() {
            this.loading = true
        },
        // 上传成功
        handleSuccess(res, file, fileList) {
            this.loading = false
            if (res.status == 200) {
                this.getList();
            }
        },
        // 分页规格改变
        handleSizeChange(val) {
            this.pagination = {
                ...this.pagination,
                current_page: val,
            };
            this.getList()
        },
        // 分页点击
        handleCurrentChange(val) {
            this.pagination = {
                ...this.pagination,
                current_page: val,
            };
            this.getList()
        }
    }
}
</script>
<style lang="scss">
.attachment {
    .el-badge {
        margin-right: 10px;
    }

    .el-badge__content.is-fixed {
        right: 12px;
    }

    .el-badge__content {
        background-color: #f84311;
        border-color: #f84311;
    }
}

#attachment {

    // 浅橙色
    .orange.el-button--primary {
        background-color: #fe872b;
    }

    //黄色
    .yellow.el-button--primary {
        background-color: #f9b633;
    }

    //默认按钮
    .el-button--primary {
        color: #fff;
        background-color: #f84311;
        border-color: #edeef3;
        border-radius: 30px;
    }

    //灰色 删除
    .el-button.dele {
        color: #333;
        background-color: #dddddd;
        border-color: #edeef3;
        border-radius: 16px;
    }

    .el-button {
        border-radius: 20px;
    }

    .el-dialog__header {
        background: #fff !important;
        border-radius: 10px 10px 0 0 !important;
        padding: 10px 20px 1px;
    }

    .el-dialog__headerbtn {
        right: 30px;
        top: 15px;
        width: 25px;
        height: 25px;
        background: url('../../../assets/images/close.png') left no-repeat;
        background-size: cover;
    }

    .el-dialog__body {
        padding: 15px 20px;
    }

    .el-dialog__title {
        color: #f84311;
        font-size: 20px;
        font-weight: bold;
        line-height: 36px;
        // font-family: MicrosoftYaHei-Bold;
    }

    .el-dialog__title::before {
        display: inline-block;
        width: 4px;
        height: 20px;
        margin-right: 15px;
        border-radius: 4px;
        background-color: #f84311;
        vertical-align: middle;
        content: '';
    }

    .el-input-group__append,
    .el-input-group__prepend {
        background-color: #f84311;
        color: #fff;
        border: none;
    }
}
</style>
<style scoped lang="scss">
.flex {
    display: flex;
    justify-content: space-between;
}

.btn {
    text-align: center;
}

.attachment {
    display: flex;

    .el-pagination {
        margin-top: 10px;
        margin-bottom: 0 !important;
    }

    .left {
        width: 225px;
        // background-color: #ff0;
        margin-right: 10px;
    }

    .right {
        width: calc(100% - 220px);
        border-left: solid 1px #ddd;
        padding-left: 10px;

        .attachment_upload {
            display: inline;
            margin-right: 10px;
        }

        .attachment_box {
            margin-top: 20px;
            height: 450px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: flex-start;

            .attachment_item.seat {
                height: 0;
            }

            .attachment_item {
                width: 16%;

                // margin-bottom: 10px;
                .active.el-image,
                .active#video {
                    border: solid 2px #f84311;
                }

                .el-image {
                    width: 100%;
                    height: 100px;
                    cursor: pointer;
                    background-color: #eee;
                }

                #video {
                    width: 100%;
                    height: 100px;
                    cursor: pointer;
                    background-color: rgba(0, 0, 0, 0.2);
                    text-align: center;

                    .el-icon-video-camera,
                    .el-icon-bell,
                    .el-icon-tickets {
                        line-height: 100px;
                        font-size: 25px;
                    }
                }

                p {
                    display: block;
                    width: 100%;
                    height: 35px;
                    line-height: 35px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
</style>
