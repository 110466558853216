<template>
  <el-dialog
    id="bouncedBox"
    :title="title"
    :visible.sync="show"
    :width="width"
    class="closeIcon"
    :append-to-body="append"
    :fullscreen="fullscreen"
    :modal-append-to-body='false'
    :modal="false"
    @close="close"
  >
    <!-- <i class="el-icon-full-screen" @click="fullScreen" /> -->
    <slot />
    <div class="submit-btn" v-if="footer" slot="footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit">提交</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'TryBounced',
  props: {
    title: {
      type: String,
      default: ''
    },
    dialogShow: {
      type: Boolean,
      default: false
    },
    width: {
      default: '80%'
    },
    append: {
      type: Boolean,
      default: false
    },
    footer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      fullscreen: false
    }
  },
  watch: {
    dialogShow: {
      handler(val) {
        this.show = val
      },
      immediate: true
    }
  },
  methods: {
    fullScreen() {
      this.fullscreen = !this.fullscreen
    },
    handleClose() {
      this.$emit('update:dialogShow', false)
      this.$emit('cancel')
    },
    close() {
      this.$emit('update:dialogShow', false)
    },
    handleSubmit() {
      this.$emit('submit')
    }
  }
}
</script>
<style lang="scss">
#bouncedBox {
  .el-dialog__headerbtn {
    //  top: 8px !important;
    right: 30px;
    top: 17px;
    width: 25px;
    height: 25px;
    background: url('../../../assets/images/close.png') left no-repeat;
    background-size: cover;
  }
  .el-dialog__headerbtn i {
    font-size: 30px;
    visibility: hidden;
  }
  .el-dialog {
    border-radius: 12px;
  }

  .el-tabs{
    border-radius: 8px !important;
  }
  /*
  .el-dialog__title {
    color: #f84311;
    font-size: 20px;
    font-weight: bold;
    line-height: 36px;
    position: relative;
    padding-left: 20px;
    // font-family: MicrosoftYaHei-Bold;
  }
  .el-dialog__title::before {
    display: inline-block;
    width: 4px;
    height: 20px;
    margin-right: 15px;
    border-radius: 4px;
    background-color: #f84311;
    vertical-align: middle;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: '';
  }*/
  //修改关闭按钮的图标
  // .el-dialog__close.el-icon-close{
  //     &::before{
  //       content: "\e78d";
  //       font-size: 30px;
  //       color: #aaa;
  //       font-weight: lighter;
  //     }
  //   }
}
</style>
