<template>
    <div>
        <div style="height: 470px ;width:100%;">
            <vueCropper ref="cropper" :img="option.img" :outputSize="option.outputSize" :outputType="option.outputType"
                :info="option.info" :canScale="option.canScale" :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" :fixed="option.fixed"
                :fixedNumber="option.fixedNumber" :full="option.full" :fixedBox="option.fixedBox"
                :canMove="option.canMove" :canMoveBox="option.canMoveBox" :original="option.original"
                :centerBox="option.centerBox" :height="option.height" :infoTrue="option.infoTrue"
                :maxImgSize="option.maxImgSize" :enlarge="option.enlarge" :mode="option.mode" @realTime="realTime">
            </vueCropper>
        </div>
        <!-- 功能区裁剪 -->
        <div class="mt-12 pt-16 pr-12 flex jcfe"
            style="width: calc(100% + 24px);margin-left: -12px;;border-top: 1px solid rgb(223, 223, 223);">
            <!-- <el-button size="mini" plain icon="el-icon-zoom-in" @click="changeScale(1)">{{ $t('放大') }}</el-button>
            <el-button size="mini" plain icon="el-icon-zoom-out" @click="changeScale(-1)">{{ $t('缩小') }}</el-button>
            <el-button size="mini" plain @click="rotateLeft">↺ {{ $t('左旋转') }}</el-button>
            <el-button size="mini" plain @click="rotateRight">↻ {{ $t('右旋转') }}</el-button> -->
            <el-button size="mini" plain @click="closeuploadImg()" class="btn1">取消</el-button>
            <el-button size="mini" type="primary" plain @click="uploadImg()" class="btn2">确定</el-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        option: {
            type: Object,
            default: {
                img: 'https://img1.baidu.com/it/u=4101939559,1931437443&fm=253&fmt=auto&app=138&f=JPEG?w=1067&h=800',             //裁剪图片的地址
                outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
                outputType: 'png',  //裁剪生成图片的格式（jpeg || png || webp）
                info: true,          //图片大小信息
                canScale: true,      //图片是否允许滚轮缩放
                autoCrop: true,      //是否默认生成截图框
                autoCropWidth: 310,  //默认生成截图框宽度
                autoCropHeight: 314, //默认生成截图框高度
                fixed: true,         //是否开启截图框宽高固定比例
                // fixedNumber: [1, 1], //截图框的宽高比例
                full: false,         //false按原比例裁切图片，不失真
                fixedBox: false,      //固定截图框大小，不允许改变
                canMove: true,      //上传图片是否可以移动
                canMoveBox: true,    //截图框能否拖动
                original: false,     //上传图片按照原始比例渲染
                centerBox: false,    //截图框是否被限制在图片里面
                height: true,        //是否按照设备的dpr 输出等比例图片
                infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
                maxImgSize: 3000,    //限制图片最大宽度和高度
                enlarge: 1,          //图片根据截图框输出比例倍数
                mode: '310px 314px'  //图片默认渲染方式
            },
        },


    },
    data() {
        return {
            previews: {},
            // option: {
            //     img: '',             //裁剪图片的地址
            //     outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
            //     outputType: 'png',  //裁剪生成图片的格式（jpeg || png || webp）
            //     info: true,          //图片大小信息
            //     canScale: true,      //图片是否允许滚轮缩放
            //     autoCrop: true,      //是否默认生成截图框
            //     autoCropWidth: 230,  //默认生成截图框宽度
            //     autoCropHeight: 150, //默认生成截图框高度
            //     fixed: false,         //是否开启截图框宽高固定比例
            //     fixedNumber: [1.53, 1], //截图框的宽高比例
            //     full: false,         //false按原比例裁切图片，不失真
            //     fixedBox: false,      //固定截图框大小，不允许改变
            //     canMove: false,      //上传图片是否可以移动
            //     canMoveBox: true,    //截图框能否拖动
            //     original: false,     //上传图片按照原始比例渲染
            //     centerBox: false,    //截图框是否被限制在图片里面
            //     height: true,        //是否按照设备的dpr 输出等比例图片
            //     infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
            //     maxImgSize: 3000,    //限制图片最大宽度和高度
            //     enlarge: 1,          //图片根据截图框输出比例倍数
            //     mode: '230px 150px'  //图片默认渲染方式
            // },
        }
    },
    methods: {
        //实时预览函数
        realTime(data) {
            this.previews = data
        },
        //图片缩放
        changeScale(num) {
            num = num || 1
            this.$refs.cropper.changeScale(num)
        },
        //向左旋转
        rotateLeft() {
            this.$refs.cropper.rotateLeft()
        },
        //向右旋转
        rotateRight() {
            this.$refs.cropper.rotateRight()
        },

        // 取消
        cancelUpload() {
            this.$emit('cancel-request');
        },

        // 取消上传
        closeuploadImg() {
            this.$emit('cropper-closerequest', this.option);
        },
        //上传图片
        uploadImg() {
            //获取截图的blob数据
            this.$refs.cropper.getCropBlob(async (data) => {

                // 将 Blob 转换为 File 类型
                const file = new File([data], this.option.name, { type: data.type });

                //拿到的是blob类型的数据，需要其他形式需要转换
                // let formData = new FormData();
                // formData.append('file', data, this.option.name);

                this.option.file = file;

                this.$emit('cropper-request', this.option);

                //调用axios上传
                //上传逻辑
                // axios({
                //     url: "你自己的上传图片路径",
                //     method: 'post',
                //     data: formData,
                //     headers: { 'Content-Type': 'multipart/form-data' }
                // }).then(res => {
                //     //添加到数据库时添加的是裁剪过的图片
                //     this.form.pic = res.data;
                //     this.$message({
                //         message: '裁剪成功',
                //         type: 'success'
                //     });
                // })
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.flex{
    display: flex;
}
.jcfe{
    justify-content: flex-end;
}
.mt-12{
    margin-top: 48px;
}

.pt-16{
    padding-top:64px;
}
.pr-12{
    padding-right: 48px;
}

.btn1 {
    width: 72px;
    height: 28px;
    padding: 0 16px;
    border: 1px solid rgb(201, 201, 201);
    border-radius: 4px;
    background: rgb(255, 255, 255);
    color: rgb(25, 25, 25);
    font-family: "harmonyos";
    font-size: 14px;
    font-weight: 400;

    &:hover {
        border: 1px solid rgb(0, 103, 209);
        // background: rgb(0, 103, 209);
        color: rgb(0, 103, 209);
    }

}

.btn2 {
    min-width: 72px;
    height: 28px;
    padding: 0 16px;
    border: none;
    background: rgb(0, 103, 209);
    color: #ffffff;
    font-family: "harmonyos";
    font-size: 14px;
    font-weight: 400;

    &:hover {
        background: rgba(0, 103, 209, 0.8);
    }
}

::v-deep() {
    .cropper-modal {
        background: rgba($color: #ffffff, $alpha: .6);
    }

    .cropper-face {
        opacity: 0;
    }

    .point8 {
        border-right: 2px solid #ffffff;
        /* 设置右边框 */
        border-bottom: 2px solid #ffffff;
        /* 设置下边框 */
        bottom: -3px;
        right: -2px;
    }

    .point6 {
        border-left: 2px solid #ffffff;
        /* 设置右边框 */
        border-bottom: 2px solid #ffffff;
        /* 设置下边框 */
        bottom: -3px;
        left: -2px;
    }

    .point3 {
        border-right: 2px solid #ffffff;
        /* 设置右边框 */
        border-top: 2px solid #ffffff;
        /* 设置下边框 */
        top: -3px;
        right: -2px;
    }

    .point1 {
        border-left: 2px solid #ffffff;
        /* 设置右边框 */
        border-top: 2px solid #ffffff;
        /* 设置下边框 */
        top: -3px;
        left: -2px;
    }

    .crop-point {
        width: 10px;
        height: 10px;
        border-radius: 0;
        background-color: transparent;
        opacity: 1;
    }

    .cropper-view-box {
        outline: 1px solid #ffffff;
    }
}
</style>