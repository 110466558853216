<template>
  <div>
    <el-switch
      v-model="inputValue"
      :disabled="disabled"
      :width="width"
      :active-text="acttext"
      :inactive-text="inatext"
      :active-color="actcolor"
      :inactive-color="inacolor"
    />
  </div>
</template>

<script>
export default {
  name: "TrySwitch",
  data() {
    return {
      inputValue: this.value == 10 ? true : false,
    };
  },
  props: {
    // 绑定的值
    value: {
      type: [Number, String],
      default: 0,
    },
    width: {
      //宽度
      type: Number,
      default: 40,
    },
    disabled: {
      //禁用属性
      type: Boolean,
      default: false,
    },
    acttext: {
      type: String,
      default: "",
    },
    inatext: {
      type: String,
      default: "",
    },
    actcolor: {
      type: String,
      default: "#409EFF",
    },
    inacolor: {
      type: String,
      default: "#DCDFE6",
    },
  },
  watch: {
    value: function (n) {
      this.inputValue = n == 10 ? true : false;
    },
    inputValue: function (n, o) {
      // 修改调用者传入的值
      if (n !== undefined && n != o) {
        console.log(n);
        this.$emit("input", n ? 10 : 20);
        this.$emit("change", n ? 10 : 20);
      }
    },
  },
};
</script>

<style></style>
