/* 布局 */
//前台
import Layout from "@views/layout";

const questionoptionRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
            {
            path: "questionoption",
            name: "QUESTIONOPTION",
            meta: {
                title: "问卷选项",
                sort: 1,
            },
            component: () => import("@views/business/questionoption/index.vue"),
            redirect: {
                name: "QUESTIONOPTION_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "QUESTIONOPTION_LIST",
                    meta: {
                        title: "问卷选项",
                        sort: 2,
                        keepAlive:true
                    },
                    component: () => import("@views/business/questionoption/list.vue"),
                },
                                {
                    path: "add",
                    name: "QUESTIONOPTION_ADD",
                    meta: {
                        title: "新增调查问卷选项",
                        parentPath: '/business/questionoption/list',
                        operate: true,
                    },
                    component: () => import("@views/business/questionoption/form.vue"),
                },
                                                {
                    path: "edit",
                    name: "QUESTIONOPTION_EDIT",
                    meta: {
                        title: "编辑调查问卷选项",
                        parentPath: '/business/questionoption/list',
                        operate: true,
                    },
                    component: () => import("@views/business/questionoption/form.vue"),
                },
                                        ],
    },


        ],
};

export default questionoptionRouter;
