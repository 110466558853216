<!-- 上传组件 支持分片上传-->
<template>
  <div
    v-loading="uploading"
    class="chunkUpload"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
  >
    <el-upload
      ref="uploadChunk"
      class="upload-block"
      name="file"
      action="111"
      :show-file-list="true"
      :before-upload="beforeExcelUpload"
      :http-request="handleUploadRequest"
    >
      <!--      <div class="el-upload__text">-->
      <!--        {{uploadText}}-->
      <!--      </div>-->
      <!-- <i class="el-icon-upload" /> -->
      <el-button type="primary" size="mini" class="el-upload__text">大文件上传</el-button>
    </el-upload>
    <!-- <div v-for="(item,index) in fileLists" :key="index">
      <i class="el-icon-document" />
      <span>{{ item.name }}</span>
    </div> -->
  </div>
</template>

<script>
import { uploadByPieces } from './aetheruploadNew'

export default {
  name: 'UploadChunk',
  props: ['uploadText', 'dialogShow'],
  data() {
    return {
      uploading: false,
      loadingText: '加载中...',
      fileList: [],
      fileLists: [],
      uploadPreview: '',
      percentage: 0
    }
  },
  computed: {
  },
  watch: {
    fileList(fileList) {
      this.fileLists.push(fileList[0])
      this.$nextTick(() => {
        this.dealUpload()
      })
    },
    dialogShow(val, value) {
      console.log(this.$refs.uploadChunk.uploadFiles)
      if (!val) {
        this.fileLists = []
        this.$refs.uploadChunk.uploadFiles = []
      }
    }
  },
  created() {
  },
  methods: {
    // 上传前
    beforeExcelUpload(file) {
      this.fileList = []
      this.uploadPreview = URL.createObjectURL(file)
    },
    // 上传请求
    handleUploadRequest(back) {
      this.fileList.push(back.file)
    },
    // 处理上传文件
    dealUpload() {
      this.uploading = true
      uploadByPieces({
        files: this.fileList,
        chunkUrl: '/api/tool/uploadslice',
        preUrl: '/api/tool/uploadslice',
        progress: (num) => {
          this.loadingText = '上传进度' + num + '%'
          this.percentage = num
        },
        success: (data) => {
          this.loadingText = data
          this.uploading = false
          this.loadingText = '上传进度'
          this.$message({
            message: "上传成功",
            type: "success"
          });
          // this.$emit('uploaded', data)
          // this.$emit('preview', this.uploadPreview)
        },
        error: (err) => {
          this.loadingText = '上传失败'
          this.uploading = false
          console.log(err)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-block {
  width: 300px;
}
.chunkUpload{
    width: 360px;
}
</style>
