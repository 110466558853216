/* 布局 */
//前台
import Layout from "@views/layout";

const activityRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "activity",
            name: "ACTIVITY",
            meta: {
                title: "活动管理",
                sort: 1,
            },
            component: () => import("@views/business/activity/index.vue"),
            redirect: {
                name: "ACTIVITY_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "ACTIVITY_LIST",
                    meta: {
                        title: "所有活动",
                        sort: 2,
                        keepAlive: true
                    },
                    component: () => import("@views/business/activity/list.vue"),
                },
                {
                    path: "list_my",
                    name: "ACTIVITY_LIST_MY",
                    meta: {
                        title: "我的活动",
                        sort: 2,
                        keepAlive: true
                    },
                    component: () => import("@views/business/activity/listmy.vue"),
                },
                {
                    path: "add",
                    name: "ACTIVITY_ADD",
                    meta: {
                        title: "新增活动表",
                        parentPath: '/business/activity/list',
                        operate: true,
                    },
                    component: () => import("@views/business/activity/form.vue"),
                },
                {
                    path: "add_my",
                    name: "ACTIVITY_ADD_MY",
                    meta: {
                        title: "新增活动表",
                        parentPath: '/business/activity/list_my',
                        operate: true,
                    },
                    component: () => import("@views/business/activity/form.vue"),
                },


                
                {
                    path: "add_content",
                    name: "ACTIVITY_CONTENT_ADD",
                    meta: {
                        title: "新增邀请函",
                        parentPath: '/business/activity/list',
                        operate: true,
                    },
                    component: () => import("@views/business/activity/contentForm.vue"),
                },
                {
                    path: "add_content_my",
                    name: "ACTIVITY_CONTENT_ADD_MY",
                    meta: {
                        title: "新增邀请函",
                        parentPath: '/business/activity/list_my',
                        operate: true,
                    },
                    component: () => import("@views/business/activity/contentForm.vue"),
                },


                {
                    path: "edit",
                    name: "ACTIVITY_EDIT",
                    meta: {
                        title: "编辑活动表",
                        parentPath: '/business/activity/list',
                        operate: true,
                    },
                    component: () => import("@views/business/activity/form.vue"),
                },     
                {
                    path: "edit_my",
                    name: "ACTIVITY_EDIT_MY",
                    meta: {
                        title: "编辑活动表",
                        parentPath: '/business/activity/list_my',
                        operate: true,
                    },
                    component: () => import("@views/business/activity/form.vue"),
                },  
                
                

                {
                    path: "edit_content",
                    name: "ACTIVITY_CONTENT_EDIT",
                    meta: {
                        title: "编辑邀请函",
                        parentPath: '/business/activity/list',
                        operate: true,
                    },
                    component: () => import("@views/business/activity/contentForm.vue"),
                },     
                {
                    path: "edit_content_my",
                    name: "ACTIVITY_CONTENT_EDIT_MY",
                    meta: {
                        title: "编辑邀请函",
                        parentPath: '/business/activity/list_my',
                        operate: true,
                    },
                    component: () => import("@views/business/activity/contentForm.vue"),
                }, 



                 {
                    path: "info",
                    name: "ACTIVITY_INFO",
                    meta: {
                        title: "详情",
                        parentPath: '/business/activity/list',
                        operate: true,
                    },
                    component: () => import("@views/business/activity/components/detail.vue"),
                },        
                {
                   path: "info_my",
                   name: "ACTIVITY_INFO_MY",
                   meta: {
                       title: "详情",
                       parentPath: '/business/activity/list_my',
                       operate: true,
                   },
                   component: () => import("@views/business/activity/components/detail.vue"),
               }, 
                 {
                    path: "add_email",
                    name: "ACTIVITY_ADDEMAIL",
                    meta: {
                        title: "邮件推送",
                        parentPath: '/business/activity/list',
                        operate: true,
                    },
                    component: () => import("@views/business/activity/emailForm.vue"),
                },
                {
                   path: "add_email_my",
                   name: "ACTIVITY_ADDEMAIL_MY",
                   meta: {
                       title: "邮件推送",
                       parentPath: '/business/activity/list_my',
                       operate: true,
                   },
                   component: () => import("@views/business/activity/emailForm.vue"),
               },



                {
                    path: "list_review",
                    name: "ACTIVITYREVIEW_LIST",
                    meta: {
                        title: "精彩活动",
                        // sort: 2,
                        keepAlive:true
                    },
                    component: () => import("@views/business/activityreview/list.vue"),
                },
                {
                    path: "questionnaire",
                    name: "QUESTIONNAIRE",
                    meta: {
                        title: "调查问卷",
                        parentPath: '/business/activity/list',
                        operate: true,
                    },
                    component: () => import("@views/business/questionnaire/index.vue"),
                },
                
            ],
        },


    ],
};

export default activityRouter;
