import API from "@utils/request";
import { PAGE_SIZE } from "@config";

const ADRESS = "/api/attachment/";

/**
* 获取附件表列表（分页）
* @author 四川创企
* @DateTime 2023/05/25
* @param {object} params
* @param {object} pages
*/
export async function getAttachmentList(params, pages) {
    try {
        return await API.get(ADRESS + "list", {
            params: {
            ...params,
            ...pages,
            },
            headers: { isLoading: true },
        });
    } catch (error) {
        return error;
    }
}

  /**
  * 获取附件表详情
  * @author 四川创企
  * @DateTime 2023/05/25
  * @param {number} id
  */
  export async function getAttachmentInfo(id) {
  try {
  return await API.post(ADRESS + "info", {
  id: {
  id,
  },
  });
  } catch (error) {
  return error;
  }
  }
  /**
  * 编辑附件表
  * @author 四川创企
  * @DateTime 2023/05/25
  * @param {object} params
  */
  export async function editAttachment(params) {
  try {
  return await API.post(ADRESS + "edit", params);
  } catch (error) {
  return error;
  }
  }

  /**
  * 新增附件表
  * @author 四川创企
  * @DateTime 2023/05/25
  * @param {object} params
  */
  export async function addAttachment(params) {
  try {
  return await API.post(ADRESS + "add", params);
  } catch (error) {
  return error;
  }
  }
  /**
  * 刪除附件表
  * @author 四川创企
  * @DateTime 2023/05/25
  * @param {number} id
  */
  export async function deleteAttachment(id) {
  try {
  return await API.post(ADRESS + "delete", {
  id: {
  id,
  },
  });
  } catch (error) {
  return error;
  }
  }
                                                                                            /**
    * 回收站
    * @author 四川创企
    * @DateTime 2023/05/25
    * @param {object} params
    * @param {object} pages
    */
    export async function getAttachmentRecycle(params, pages) {
    try {

    return await API.get(ADRESS + "recycle", {
    params: {
    ...params,
    ...pages,
    },
    headers: { isLoading: true },
    });
    } catch (error) {
    return error;
    }
    }

    /**
    * 逻辑刪除
    * @author 四川创企
    * @DateTime 2023/05/25
    * @param {number} id
    */
    export async function destroyAttachment(id) {
    try {
    return await API.post(ADRESS + "destroy", {
    id: {
    id,
    },
    });
    } catch (error) {
    return error;
    }
    }

    /**
    * 还原
    * @author 四川创企
    * @DateTime 2023/05/25
    * @param {number} id
    */
    export async function restoreAttachment(id) {
    try {
    return await API.post(ADRESS + "restore", {
    id: {
    id,
    },
    });
    } catch (error) {
    return error;
    }
    }
  

