import API from "@utils/request";
import { PAGE_SIZE } from "@config";

const ADRESS = "/api/attachmentcate/";

/**
* 获取附件分类表列表（分页）
* @author 四川创企
* @DateTime 2023/05/25
* @param {object} params
* @param {object} pages
*/
export async function getAttachmentCateList(params, pages) {
    try {
        return await API.get(ADRESS + "list", {
            params: {
            ...params,
            ...pages,
            },
            headers: { isLoading: true },
        });
    } catch (error) {
        return error;
    }
}

  /**
  * 获取附件分类表详情
  * @author 四川创企
  * @DateTime 2023/05/25
  * @param {number} id
  */
  export async function getAttachmentCateInfo(id) {
  try {
  return await API.post(ADRESS + "info", {
  id: {
  id,
  },
  });
  } catch (error) {
  return error;
  }
  }
  /**
  * 编辑附件分类表
  * @author 四川创企
  * @DateTime 2023/05/25
  * @param {object} params
  */
  export async function editAttachmentCate(params) {
  try {
  return await API.post(ADRESS + "edit", params);
  } catch (error) {
  return error;
  }
  }

  /**
  * 新增附件分类表
  * @author 四川创企
  * @DateTime 2023/05/25
  * @param {object} params
  */
  export async function addAttachmentCate(params) {
  try {
  return await API.post(ADRESS + "add", params);
  } catch (error) {
  return error;
  }
  }
  /**
  * 刪除附件分类表
  * @author 四川创企
  * @DateTime 2023/05/25
  * @param {number} id
  */
  export async function deleteAttachmentCate(id) {
  try {
  return await API.post(ADRESS + "delete", {
  id: {
  id,
  },
  });
  } catch (error) {
  return error;
  }
  }
                                                            

