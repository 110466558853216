// 建立字典本地存储
import { createDictionaryCash } from "@api/DataDictionary";
const state = {
    dictionaries: {}, //字典集合
};
const mutations = {
    dictionaries: (state, obj) => {
        state.dictionaries = obj;
    },
};

const actions = {
    async setDictionaries({ commit }) {
        await createDictionaryCash(false);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};