// 建立配置本地存储
const state = {
    businessdata: {}, //字典集合
};
const mutations = {
    businessdata: (state, obj) => {
        state.businessdata = obj;
    },
};

const actions = {
    async setBusinessData({ commit }) {
        await commit("businessdata", {});
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};