import API from "@utils/request";

/**
 * 获取表字段清单
 * @param {stirng} moduleName
 * @param {stirng} moduleType
 */
export async function getFieldArrByModule(moduleName, moduleType) {
  try {
    return await API.post( "/api/field/getfieldarrbymodule", {
      'moduleName': moduleName,
      'moduleType': moduleType
    });
  } catch (error) {
    return error;
  }
}


/**
 * 新增模型数据
 * @param {string} moduleName
 * @param {object} params
 */
export async function addModuleFromData(moduleName, params) {
  try {
    return await API.post("/api/" + moduleName.toLowerCase() + "/add", params);
  } catch (error) {
    return error;
  }
}

/**
 * 编辑模型数据
 * @param {string} moduleName
 * @param {object} params
 */
export async function editModuleFromData(moduleName, params) {
  try {
    return await API.post("/api/" + moduleName.toLowerCase() + "/edit", params);
  } catch (error) {
    return error;
  }
}


/**
 * 获取模型数据详情
 * @param {string} moduleName
 * @param {Number} id
 */
export async function getModuleDataInfo(moduleName, id) {
  try {
    return await API.post("/api/" + moduleName.toLowerCase() + "/info", {
      id: {
        id,
      },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取模型数据列表
 * @param {string} moduleName
 * @param {object} params
 * @param {object} pages
 */
export async function getModuleDataList(moduleName, params, pages) {
  try {
    return await API.get("/api/" + moduleName.toLowerCase() + "/list", {
      params: {
        ...params,
        ...pages,
      },
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取我的模型数据列表
 * @param {string} moduleName
 * @param {object} params
 * @param {object} pages
 */
export async function getModuleDataMyList(moduleName, params, pages) {
    try {
      return await API.get("/api/" + moduleName.toLowerCase() + "/mylist", {
        params: {
          ...params,
          ...pages,
        },
        headers: { isLoading: true },
      });
    } catch (error) {
      return error;
    }
  }

/**
* 回收站
* @author 四川创企
* @DateTime 2023/08/07
* @param {string} moduleName
* @param {object} params
* @param {object} pages
*/
export async function getModuleDataRecycle(moduleName, params, pages) {
  try {
    return await API.get("/api/" + moduleName.toLowerCase() + "/recycle", {
      params: {
        ...params,
        ...pages,
      },
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}

/**
* 逻辑刪除模型数据
* @author 四川创企
* @DateTime 2023/08/07
* @param {string} moduleName
* @param {number} id
*/
export async function destroyModuleData(moduleName,id) {
  try {
      return await API.post("/api/" + moduleName.toLowerCase()  + "/destroy", {
          id: {
              id,
          },
      });
  } catch (error) {
      return error;
  }
}
/**
* 还原模型数据
* @author 四川创企
* @DateTime 2023/08/07
* @param {string} moduleName
* @param {number} id
*/
export async function restoreModuleData(moduleName,id) {
  try {
      return await API.post("/api/" + moduleName.toLowerCase()  + "/restore", {
          id: {
              id,
          },
      });
  } catch (error) {
      return error;
  }
}
/**
* 彻底刪除模型数据
* @author 四川创企
* @DateTime 2023/08/07
* @param {string} moduleName
* @param {number} id
*/
export async function deleteModuleData(moduleName,id) {
  try {
      return await API.post("/api/" + moduleName.toLowerCase()  + "/delete", {
          id: {
              id,
          },
      });
  } catch (error) {
      return error;
  }
}

/**
* 修改单字段值
* @author 四川创企
* @DateTime 2023/08/07
* @param {number} id
*/
export async function updateModuleDataField(moduleName,id, field, value) {
  try {
      return await API.post("/api/" + moduleName.toLowerCase()  + "/updatefield", {
          id: id,
          field: field,
          value: value,
      });
  } catch (error) {
      return error;
  }
}

/**
* 导出模型数据
* @author 四川创企
* @DateTime 2023/08/07
* @param {string} moduleName
* @param {object} params
*/
export async function exportModuleData(moduleName,params) {
  try {
      return await API.get("/api/" + moduleName.toLowerCase()  + "/export", {
          params: {
              ...params,
          },
          headers: { isLoading: true },
      });
  } catch (error) {
      return error;
  }
}

/**
* 下载导入模板  
* @author 四川创企
* @DateTime 2023/08/07
* @param {string} moduleName
*/
export async function importModuleTemplate(moduleName) {
  try {
      return await API.post("/api/" + moduleName.toLowerCase()  + "/importtemplate", {});
  } catch (error) {
      return error;
  }
}
/**
* 导入模型数据  
* @author 四川创企
* @DateTime 2023/08/07
* @param {string} moduleName
* @param {object} importfile
*/
export async function importModuleData(moduleName,importfile) {
  try {
    console.log(importfile);
      return await API.post("/api/" + moduleName.toLowerCase()   + "/import", { importfile: importfile });
  } catch (error) {
      return error;
  }
}