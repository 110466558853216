/* 布局 */
//前台
import Layout from "@views/layout";

const activityreviewRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
            {
            path: "activityreview",
            name: "ACTIVITYREVIEW",
            meta: {
                title: "精彩活动",
                sort: 1,
            },
            component: () => import("@views/business/activityreview/index.vue"),
            redirect: {
                name: "ACTIVITYREVIEW_LIST",
            },
            children: [
                // {
                //     path: "list",
                //     name: "ACTIVITYREVIEW_LIST",
                //     meta: {
                //         title: "精彩活动",
                //         sort: 2,
                //         keepAlive:true
                //     },
                //     component: () => import("@views/business/activityreview/list.vue"),
                // },
                                {
                    path: "add",
                    name: "ACTIVITYREVIEW_ADD",
                    meta: {
                        title: "新增精彩活动表",
                        parentPath: '/business/activityreview/list',
                        operate: true,
                    },
                    component: () => import("@views/business/activityreview/form.vue"),
                },
                                                {
                    path: "edit",
                    name: "ACTIVITYREVIEW_EDIT",
                    meta: {
                        title: "编辑精彩活动表",
                        parentPath: '/business/activityreview/list',
                        operate: true,
                    },
                    component: () => import("@views/business/activityreview/form.vue"),
                },
                                        ],
    },


        ],
};

export default activityreviewRouter;
