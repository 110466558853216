<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量

.side-bar {
  // width: 225px !important;
  height: calc(100vh - 50px);
  overflow: auto;
  position: relative;
  background: $menuBg;

  // padding-top: 16px;
  .side-menu {
    height: calc(100% - 60px);
    overflow-y: auto;
    border: none;
  }

  .side-menu:not(.el-menu--collapse) {
    // width: 220px;
  }
}

::v-deep() {
  .side-menu.el-menu--collapse {
    width: 64px !important;
    padding: 0 8px 0 8px !important;

    .el-menu-item {
      // padding-right: 8px !important;
    }
  }

  .side-menu.el-menu {
    width: 225px;
  }
}
</style>
<style lang="scss">
@import "@assets/styles/variables.scss"; //公共样式变量

.no-child.is-active {
  background-color: #E6F1FD !important;
  color: #0067D1 !important;
}

.el-menu-item-group {
  background-color: #f2f4f6 !important;
}

.el-menu-item-group ul,
.el-menu-item-group ul .el-menu-item {
  background-color: #fff;
}

.el-menu-item-group ul .el-menu-item {
  background-color: #fff;
}

.el-menu-item-group ul .el-menu-item:last-child {
  border-bottom: none !important;
}

.el-menu-item-group .is-active {
  background-color: #E6F1FD !important;
}

.el-menu-item-group .el-menu-item-group__title {
  display: none !important;
}

.el-aside {
  padding-top: 15px;
  box-shadow: 3px 0 3px rgba(0, 21, 41, 0.08);
}

.el-container {
  margin-left: 3px;
}

.el-menu--collapse .el-menu-item {
  text-align: center !important;
  font-size: 18px !important;

}

.el-aside .side-bar .side-menu {
  padding: 0 16px 0 8px;
}

.el-submenu__title span {
  padding-left: 5px;
}

.el-submenu__title {
  padding: 0 16px 0 20px !important;
  height: 48px !important;
  line-height: 48px !important;
  border-radius: 4px;

  &:hover {
    background: #E6F1FD !important;
    color: #0067D1 !important;

    i.el-icon-s-goods,
    .el-icon-monitor,
    .el-icon-star-off {
      background: #E6F1FD !important;
      color: #0067D1 !important;
    }
  }
}

.el-menu-item {
  height: 48px !important;
  line-height: 48px !important;
  border-radius: 4px;
  // padding-left: 8px !important;
  padding-right: 16px !important;
  margin-bottom: 4px !important;

  &:hover {
    background: #E6F1FD !important;
    color: #0067D1 !important;

    i.el-icon-s-goods,
    .el-icon-monitor,
    .el-icon-star-off {
      background: #E6F1FD !important;
      color: #0067D1 !important;
    }
  }
}
</style>

<template>

  <section class="side-bar" style="background:#ffffff;" id="try-side">


    <el-menu class="side-menu" unique-opened router background-color="transparent" text-color="#000000"
      active-text-color="#ffffff" :default-active="active" :collapse="isCollapse">
      <SidebarGroup :isCollapse="isCollapse" v-for="mitem in menulist" :key="mitem.amenu_id"
        :index="mitem.amenu_id + ''" :item="mitem" :messages="messgList" />
    </el-menu>
  </section>
</template>

<script>
// import { getUserMenu } from "@api/AdminMenu";
import SidebarGroup from "./SidebarGroup";
import { mapActions, mapState } from "vuex";

import store from "../../../../../view/src/store";

export default {
  computed: {
    ...mapState('system', ['messgList'])
  },
  //组件
  components: {
    SidebarGroup,
  },
  name: "Sidebar",
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    },
    leftmenu: {
      type: [String, Number, Array, Date],
      default: ""
    },
    leftactive: {
      type: [String],
      default: ""
    },
  },
  data() {
    return {
      // active: "/homepage",
      active: this.leftactive,
      menulist: this.leftmenu,
      messageList: []

    };
  },
  watch: {
    $route(val) {
      // this.active = val.path;
      // this.active = val.fullPath;//使用完全体URL处理数据
      this.active = val.meta.parentPath ? val.meta.parentPath : val.fullPath;//使用完全体URL处理数据

    },
    leftmenu(val) {
      this.menulist = val;

    },



  },
  created() {
    // this.active = this.$route.path;
    this.active = this.$route.meta.parentPath ? this.$route.meta.parentPath : this.$route.fullPath;
  },

  mounted() {
    this.refreshNum();

  },
  methods: {

    ...mapActions("system", ["getDealtNum"]),
    handleEvent(message) {

    },

    // // 刷新菜单待办数量
    async refreshNum() {

      await this.getDealtNum();

      console.log(store.state.system.messgList, ">>>>>>>")

      this.messageList = store.state.system.messgList;
    }


  },
};
</script>
