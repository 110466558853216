// 建立配置本地存储
import { createGroupCash } from "@api/DataGroup";
const state = {
    configdata: {}, //字典集合
};
const mutations = {
    configdata: (state, obj) => {
        state.configdata = obj;
    },
};

const actions = {
    async setDataConfig({ commit }) {
        await createGroupCash(false);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};