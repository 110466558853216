/* 布局 */
//前台
import Layout from "@views/layout";

const questionnaireRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "questionnaire",
            name: "QUESTIONNAIRE",
            meta: {
                title: "调查问卷",
                sort: 1,
            },
            component: () => import("@views/business/questionnaire/index.vue"),
        },


    ],
};

export default questionnaireRouter;
