<style lang="scss" scoped></style>

<template>
    <div>
        <el-form :model="formData" :rules="rules" ref="formData" label-width="120px" v-loading="loading" class="page-form">
            <template v-for="Item in formFieldData">
                <el-form-item :label="Item.cf_name" :prop="Item.cf_fieldname">
                    <!-- 输入框 -->
                    <template v-if="Item.cf_control == 'input'">
                        <try-input v-model="formData[Item.cf_fieldname]" :placeholder="Item.cf_placeholder" :maxlength="Item.cf_length > 0 ? Item.cf_length : 50"></try-input>
                    </template>
                    <!-- 多行输入框 -->
                    <template v-else-if="Item.cf_control == 'textarea'">
                        <try-input v-model="formData[Item.cf_fieldname]" :placeholder="Item.cf_placeholder" type="textarea"
                            :maxlength="Item.cf_length > 0 ? Item.cf_length : 200" :rows="5" clearable></try-input>
                    </template>
                    <!-- 计数器 -->
                    <template v-else-if="Item.cf_control == 'InputNumber'">
                        <try-input v-model="formData[Item.cf_fieldname]" type="number"></try-input>
                    </template>
                    <!-- 时间、日期选择 -->
                    <template
                        v-else-if="Item.cf_control == 'DatePicker' || Item.cf_control == 'DateTimePicker' || Item.cf_control == 'TimePicker'">
                        <try-input v-model="formData[Item.cf_fieldname]" :type="Item.cf_control"></try-input>
                    </template>
                    <!-- 密码 -->
                    <template v-else-if="Item.cf_control == 'password'">
                        <try-input v-model="formData[Item.cf_fieldname]" :placeholder="Item.cf_placeholder" type="password" :maxlength="Item.cf_length > 0 ? Item.cf_length : 20"
                            clearable></try-input>
                    </template>
                    <!-- 单选 -->
                    <template v-else-if="Item.cf_control == 'radio'">
                        <try-radio v-model="formData[Item.cf_fieldname]" :options="Item.optionData"
                            v-if="Item.cf_sourcetype == 'STATIC'" isButton></try-radio>
                        <try-radio v-model="formData[Item.cf_fieldname]" :dictName="Item.cf_dictionary"
                            v-if="Item.cf_sourcetype == 'DICTIONARY'" isButton></try-radio>
                        <try-radio v-model="formData[Item.cf_fieldname]" :url="'/api/' + Item.cf_method + '/getkeyval'"
                            :parameData="Item.parameData" v-if="Item.cf_sourcetype == 'API'" isButton></try-radio>
                    </template>
                    <!-- 下拉 -->
                    <template v-else-if="Item.cf_control == 'select'">
                        <try-select v-model="formData[Item.cf_fieldname]" :options="Item.optionData"
                            v-if="Item.cf_sourcetype == 'STATIC'"></try-select>
                        <try-select v-model="formData[Item.cf_fieldname]" :dictName="Item.cf_dictionary"
                            v-if="Item.cf_sourcetype == 'DICTIONARY'"></try-select>
                        <try-select v-model="formData[Item.cf_fieldname]" :url="'/api/' + Item.cf_method + '/getkeyval'"
                            :parameData="Item.parameData" v-if="Item.cf_sourcetype == 'API'"></try-select>
                    </template>
                    <!-- 多选下拉 -->
                    <template v-else-if="Item.cf_control == 'selectAll'">
                        <try-select v-model="formData[Item.cf_fieldname]" :options="Item.optionData"
                            v-if="Item.cf_sourcetype == 'STATIC'" multiple></try-select>
                        <try-select v-model="formData[Item.cf_fieldname]" :dictName="Item.cf_dictionary"
                            v-if="Item.cf_sourcetype == 'DICTIONARY'" multiple></try-select>
                        <try-select v-model="formData[Item.cf_fieldname]" :url="'/api/' + Item.cf_method + '/getkeyval'"
                            :parameData="Item.parameData" v-if="Item.cf_sourcetype == 'API'" multiple></try-select>
                    </template>
                    <!-- 多选 -->
                    <template v-else-if="Item.cf_control == 'checkbox'">
                        <try-checkbox v-model="formData[Item.cf_fieldname]" :options="Item.optionData"
                            v-if="Item.cf_sourcetype == 'STATIC'" isButton></try-checkbox>
                        <try-checkbox v-model="formData[Item.cf_fieldname]" :dictName="Item.cf_dictionary"
                            v-if="Item.cf_sourcetype == 'DICTIONARY'" isButton></try-checkbox>
                        <try-checkbox v-model="formData[Item.cf_fieldname]" :url="'/api/' + Item.cf_method + '/getkeyval'"
                            :parameData="Item.parameData" v-if="Item.cf_sourcetype == 'API'" isButton></try-checkbox>
                    </template>
                    <!-- 开关 -->
                    <template v-else-if="Item.cf_control == 'Switch'">
                        <try-switch v-model="formData[Item.cf_fieldname]"></try-switch>
                    </template>
                    <!-- 滑块 -->
                    <template v-else-if="Item.cf_control == 'Slider'">
                        <el-slider v-model="formData[Item.cf_fieldname]"></el-slider>
                    </template>
                    <!-- 单图上传 -->
                    <template v-else-if="Item.cf_control == 'UploadImg'">
                        <try-upload v-model="formData[Item.cf_fieldname]" upType="IMG"></try-upload>
                    </template>
                    <!-- 单文件上传 -->
                    <template v-else-if="Item.cf_control == 'UploadFile'">
                        <try-upload v-model="formData[Item.cf_fieldname]" upType="FILE"></try-upload>
                    </template>
                    <!-- 多图片上传 -->
                    <template v-else-if="Item.cf_control == 'UploadImgArr'">
                        <try-upload v-model="formData[Item.cf_fieldname]" upType="IMGARR"></try-upload>
                    </template>
                    <!-- 多文件上传 -->
                    <template v-else-if="Item.cf_control == 'UploadFileArr'">
                        <try-upload v-model="formData[Item.cf_fieldname]" upType="FILEARR"></try-upload>
                    </template>
                    <!-- 富文本 -->
                    <template v-else-if="Item.cf_control == 'editor'">
                        <try-editor v-model="formData[Item.cf_fieldname]"></try-editor>
                    </template>
                    <!-- 组织机构 -->
                    <template v-else-if="Item.cf_control == 'Department'">
                        <try-selecttree v-model="formData[Item.cf_fieldname]" dictName="Dep"></try-selecttree>
                    </template>
                    <!-- 带权限组织机构 -->
                    <template v-else-if="Item.cf_control == 'DepartmentAuth'">
                        <try-selecttree v-model="formData[Item.cf_fieldname]" dictName="DepAuth"></try-selecttree>
                    </template>
                    <!-- 树形下拉选择 -->
                    <template v-else-if="Item.cf_control == 'SelectTree'">
                        <try-selecttree v-model="formData[Item.cf_fieldname]"
                            :dictName="Item.cf_dictionary"></try-selecttree>
                    </template>

                    <!-- 地理位置选择 -->
                    <template v-else-if="Item.cf_control == 'city'">
                        <try-district v-model="formData[Item.cf_fieldname]"></try-district>
                    </template>
                    <!-- 单图选择 -->
                    <template v-else-if="Item.cf_control == 'UploadAttachment'">
                        <try-uploadattachment v-model="formData[Item.cf_fieldname]" upType="IMG"></try-uploadattachment>
                    </template>
                    <!-- 多图选择 -->
                    <template v-else-if="Item.cf_control == 'UploadAttachmentArr'">
                        <try-uploadattachment v-model="formData[Item.cf_fieldname]" upType="IMGARR"></try-uploadattachment>
                    </template>
                    <!-- 单文件选择 -->
                    <template v-else-if="Item.cf_control == 'UploadAttachmentFile'">
                        <try-uploadattachment v-model="formData[Item.cf_fieldname]" upType="FILE"></try-uploadattachment>
                    </template>
                    <!-- 多文件选择 -->
                    <template v-else-if="Item.cf_control == 'UploadAttachmentFileArr'">
                        <try-uploadattachment v-model="formData[Item.cf_fieldname]" upType="FILEARR"></try-uploadattachment>
                    </template>

                </el-form-item>
            </template>
            <el-form-item class="submitform">
                <el-button @click="handleCancle">取 消</el-button>
                <el-button type="primary" v-prikey="moduleAuth + '_ADD'" @click="handleSubmit" :loading="loading"> 确 定
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { getFieldArrByModule, getModuleDataInfo, addModuleFromData, editModuleFromData } from "@api/CommonModule";
import { moveToErr } from "@utils";
export default {
    name: "TryFrom",
    data() {
        return {
            loading: false,//加载loading
            priKey: "",//主键标识
            pkId: this.id,//组件ID值
            moduleName: this.moduleTage,//模型标识
            formData: {},
            formFieldData: {},//字段清单
            rules: {},//验证规则
            moduleAuth: "",
        };
    },
    props: {
        id: { // 数据标识
            type: [Number, String],
            default: 0
        },
        refId: { // 引用标识
            type: [Number, String],
            default: 0
        },
        moduleTage: { // 模块标识
            type: String,
            default: ''
        },

    },
    watch: {

    },
    created() {
        this.initData();
    },
    activated() {
        // 激活再次加载
        this.initData();
    },
    mounted() { },
    methods: {
        /** 初始化表单数据 */
        async initData() {
            this.moduleAuth = this.moduleName.toUpperCase();
            if (this.moduleTage != "" && this.moduleTage != undefined) {
                this.loading = true;
                this.formData = {};
                let { data: res } = await getFieldArrByModule(this.moduleTage, "form");
                res.forEach((element, index) => {
                    if (element.cf_default) {
                        this.formData[element.cf_fieldname] = element.cf_default
                    }
                    if (element.cf_control == "checkbox") {
                        this.formData[element.cf_fieldname] = [];
                    }
                    if (element.cf_validate == "require") {
                        this.rules[element.cf_fieldname] = { required: true, message: "请输入" + element.cf_name, trigger: "blur" };
                    }
                    if (element.cf_ispri == 1) {
                        this.priKey = element.cf_fieldname;
                        res.splice(index, 1);
                    }

                });

                this.formFieldData = res;

                if (this.pkId > 0 || this.refId > 0) {
                    let { data: resdata, status: status, msg: msg } = await getModuleDataInfo(this.moduleName, this.pkId > 0 ? this.pkId : this.refId);
                    if (status != 200) {
                        this.$message({ message: msg, type: "error" });
                    };
                    this.formData = resdata;
                    this.formFieldData.forEach((element, index) => {
                        if (element.cf_control == "checkbox" && (this.formData[element.cf_fieldname] == "" || this.formData[element.cf_fieldname] == null)) {
                            this.formData[element.cf_fieldname] = [];
                        }
                    });
                }
                this.formData[this.priKey] = this.pkId;
                this.loading = false;
                this.$forceUpdate();
            }
        },
        /** 取消表单填写 */
        handleCancle() {
            this.$refs["formData"].clearValidate();
            this.$router.go(-1);
        },
        /** 提交表单*/
        async handleSubmit() {
            this.$refs["formData"].validate(async (valid) => {
                if (valid) {
                    var res;
                    this.loading = true;
                    let submitFormData = JSON.parse(JSON.stringify(this.formData));
                    /** 请求操作接口*/
                    if (this.pkId > 0) {
                        res = await addModuleFromData(this.moduleName, submitFormData);
                    } else {
                        res = await editModuleFromData(this.moduleName, submitFormData);
                    }
                    this.loading = false;
                    if (res.status != 200)
                        return;
                    this.$message({
                        message: res.msg,
                        type: "success"
                    });
                    this.handleCancle(); // 处理成功，返回到列表页
                } else {
                    moveToErr(this);
                }
            });
        },
    },
};
</script>