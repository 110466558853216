// 建立树形字典本地存储
import { createDataDicttreeCash } from "@api/DataDicttree";
const state = {
    dicttree: {}, //字典集合
};
const mutations = {
    dicttree: (state, obj) => {
        state.dicttree = obj;
    },
};

const actions = {
    async setDicttree({ commit }) {
        let { data: res } = await createDataDicttreeCash();
        if(!res) return;
        let keyArr = Object.keys(res);
        let valueArr = Object.values(res);

        keyArr.forEach((element, index) => {
            element = "L-" + element;
            res[element] = convertToList(valueArr[index]);
        });
        await commit("dicttree", res);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};

//树形数据转换为列表数据
function convertToList(list) {
    let res = [];
    for (const item of list) {
        const { children, ...i } = item;
        if (children && children.length) {
            res = res.concat(convertToList(children));
        }
        res.push(i);
    }
    return res;
}