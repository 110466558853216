/* 布局 */
//前台
import Layout from "@views/layout";

const expertstationedapplyRouter = {
    path: "/business",
    component: Layout,
    redirect: {
        name: "BUSINESS"
    },
    children: [
        {
            path: "expertstationedapply",
            name: "EXPERTSTATIONEDAPPLY",
            meta: {
                title: "驻站专家管理",
                sort: 1,
            },
            component: () => import("@views/business/expertstationedapply/index.vue"),
            redirect: {
                name: "EXPERTSTATIONEDAPPLY_LIST",
            },
            children: [
                {
                    path: "list",
                    name: "EXPERTSTATIONEDAPPLY_LIST",
                    meta: {
                        title: "驻站专家管理",
                        sort: 2,
                        keepAlive: true
                    },
                    component: () => import("@views/business/expertstationedapply/list.vue"),
                },
                {
                    path: "add",
                    name: "EXPERTSTATIONEDAPPLY_ADD",
                    meta: {
                        title: "新增驻站专家",
                        parentPath: '/business/expertstationedapply/list',
                        operate: true,
                    },
                    component: () => import("@views/business/expertstationedapply/form.vue"),
                },
                {
                    path: "add",
                    name: "EXPERTSTATIONEDAPPLY_EDIT",
                    meta: {
                        title: "编辑驻站专家",
                        parentPath: '/business/expertstationedapply/list',
                        operate: true,
                    },
                    component: () => import("@views/business/expertstationedapply/form.vue"),
                },
                {
                    path: "info",
                    name: "EXPERTSTATIONEDAPPLY_INFO",
                    meta: {
                        title: "驻站专家详情",
                        parentPath: '/business/expertstationedapply/list',
                        operate: true,
                    },
                    component: () => import("@views/business/expertstationedapply/info.vue"),
                },
            ],
        },


    ],
};

export default expertstationedapplyRouter;
