<template>
    <div>
        <aceEditor v-model="inputValue" @init="editorInit" :options="options" :width="width" :height="height"
           :lang="lang" ref="myTextEditor">
        </aceEditor>
    </div>
</template>

<script>
import aceEditor from "vue2-ace-editor"
export default {
    name: "TryAceEditor",
    components: {
        aceEditor
    },
    data() {
        return {
            inputValue: this.value,
            options: {
                /*vue2-ace-editor编辑器配置自动补全等*/
                enableBasicAutocompletion: true,
                enableSnippets: true,
                enableLiveAutocompletion: true /*自动补全*/,
            },
        };
    },
    props: {
        // 绑定的值
        value: {
            type: String,
            default: ""
        },
        disabled: {//禁用属性
            type: Boolean,
            default: false
        },
        // 高度 默认600
        height: {
            type: Number,
            default: () => {
                return 600;
            },
        },
        // 宽度 默认800
        width: {
            type: Number,
            default: () => {
                return 800;
            },
        },
        lang: {
            type: String,
            default: "html"
        }
    },
    watch: {
        value: function (n) {
            this.inputValue = n;
        },
        inputValue: function (newValue) {
            // 修改调用者传入的值
            this.$emit('change', newValue)
        },
    },
    methods: {
        editorInit() {
            require("brace/theme/chrome");
            require("brace/theme/solarized_light");
            require("brace/ext/language_tools");
            require("brace/mode/yaml");
            require("brace/mode/javascript");
            require("brace/mode/java");
            require("brace/mode/less");
            require("brace/mode/json");
            require("brace/theme/tomorrow_night_blue");
            require("brace/theme/monokai");
            require("brace/theme/tomorrow_night_eighties");
            require("brace/theme/ambiance");
            require("brace/theme/tomorrow_night");
            require("brace/theme/chaos");
            require("brace/theme/tomorrow");
            require("brace/snippets/javascript");
            require("brace/snippets/java");
            require("brace/ext/emmet");
            require("brace/ext/searchbox");
            require("brace/mode/json");
            require("brace/snippets/json");
        },
    }
};
</script>

<style></style>
