<template>
    <div>
        <div class="main-box" :class="upType == 'IMG' || upType == 'IMGARR' ? '' : 'main-file'">
            <div class="filelist" v-if="fileList.length > 0">
                <div class="file-img" v-if="upType == 'IMG' || upType == 'IMGARR'">
                    <div class="file-item" v-for="(item, index) in fileList">
                        <img :src="item.att_url" class="attachmentImg" />
                        <span class="img-actions attachmentImg">
                            <span @click.stop="handleRemove(index)">
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="file-file" v-else>
                    <p v-for="(item, index) in fileList">
                        <span @click.stop="handleRemove(index)" title="删除">
                            {{ item.att_title }}
                            <i class="el-icon-delete"></i>
                        </span>
                    </p>

                </div>
            </div>
            <div v-if="upType == 'IMG' || upType == 'IMGARR'">
                <div class="attachment_imgBox" @click="openList()" v-if="upOption.fileLimit > 1 || fileList.length == 0">
                    <i class="el-icon-camera" />
                </div>
            </div>
            <div v-else>
                <el-button size="small" type="primary" @click="openList()">点击上传</el-button>

            </div>
        </div>
        <try-attachment ref="attachment" :show.sync="dialogShow" :attachment-type="attachmentType" @func="getData"
            @on-close="closeAttachment" :disabled="disabled"></try-attachment>
    </div>
</template>
<script>
export default {
    name: "TryUploadattachment",
    props: {
        // 值
        value: [String, Object, Array],

        //上传文件类型
        upType: {
            type: String,
            default: 'IMG'
        },
        // 最大允许上传个数
        fileLimit: {
            type: Number,
            default: 99,
        },
        disabled: {//禁用属性
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogShow: false,
            attachmentType: 2,
            uploadUrl: "/api/tool/uploadimg/", // 上传的图片服务器地址
            paramsData: {
                "accessToken": sessionStorage.getItem("token"),
            }, // 上传携带的参数，看需求要不要
            fileList: [],
            tempFileList: [], // 因为 fileList为只读属性，所以用了一个中间变量来进行数据改变的交互。
            upOption: {
                "fileSize": this.fileSize,
                "fileType": this.fileType,
                "fileLimit": this.fileLimit,
            },
            // 文件列表类型 text/picture/picture-card
            listType: 'picture'

        };
    },
    watch: {
        value: {
            handler: function (newVal, oldVa) {
                if (newVal != '' && newVal != undefined) {
                    this.fileList = JSON.parse(newVal);
                }
                else this.fileList = [];
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        // 是否显示提示
        showTip() {
            return this.isShowTip && (this.upOption.fileType || this.upOption.fileSize);
        },
        fileTypeName() {
            let typeName = "";
            this.upOption.fileType.forEach((item) => {
                typeName += `${item}，`;
            });
            return typeName;
        },
        fileAccept() {
            let fileAccept = "";
            this.upOption.fileType.forEach((element) => {
                fileAccept += `.${element},`;
            });
            return fileAccept;
        },
    },
    created() {
        this.initData();
    },
    methods: {
        // 移除图片数据
        handleRemove(index) {

            console.log(index);
            this.fileList.splice(index, 1);
            this.changeFileList(this.fileList);
        },
        changeFileList(data) {
            let that = this;
            that.fileList = [];
            console.log(data);
            data.forEach(function (item, index) {
                var obj = {};
                obj.att_id = item.att_id;
                obj.att_title = item.att_title;
                obj.att_url = item.att_url;
                that.fileList.push(obj);
            });
            const tempFileList = JSON.stringify(that.fileList);

            this.$emit("input", that.fileList.length > 0 ? tempFileList : null);
        },
        closeAttachment(e) {
            this.dialogShow = e;
        },
        getData(data) {
            let that = this;
            if ((parseInt(this.fileList.length) + parseInt(data.length)) > this.upOption.fileLimit) {
                this.$message.error('最多只能选择' + this.upOption.fileLimit + '图片')
            } else {
                console.log(data);
                data.forEach(function (item, index) {
                    var obj = {};
                    obj.att_id = item.att_id;
                    obj.att_title = item.att_title;
                    obj.att_url = item.att_url;
                    that.fileList.push(obj);
                });
                this.dialogShow = false
                const tempFileList = JSON.stringify(that.fileList);
                this.$emit("input", that.fileList.length > 0 ? tempFileList : null);
            }
        },
        // 打开附件弹窗
        openList() {
            if (this.disabled === false) {
                this.dialogShow = true
                this.attachmentType = 2
                this.$nextTick(() => {
                    this.$refs.attachment.getList()
                    this.$refs.attachment.$refs.AttachmentCate.getCateList(0)
                });
            }
        },
        /*初始化数据*/
        async initData() {
            if (this.upType == "IMG" || this.upType == "IMGARR") {
                this.upOption.fileLimit = this.upType == "IMG" ? 1 : this.upOption.fileLimit;
                this.listType = this.upType == "IMG" ? "picture" : "picture-card";
            } else {
                this.upOption.fileLimit = this.upType == "FILE" ? 1 : this.upOption.fileLimit;
                this.uploadUrl = "/api/tool/uploadfile/";
                this.listType = "text";
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.attachment_imgBox .img-actions {
    position: absolute;
    background: rgba($color: #000000, $alpha: 0.3);
    top: 1px;
    left: 3px;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    opacity: 0;
}

.attachment_imgBox .img-actions>span {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.attachment_imgBox .img-actions>span>i {
    color: #f00;
    font-size: 22px;
}

.attachment_imgBox:hover .img-actions {
    opacity: 1;
}

.filelist .file-item:hover .img-actions {
    opacity: 1;
}

.filelist {

    box-sizing: border-box;
    display: flex;
}

.filelist .file-img {
    display: flex;
}

.filelist .file-item {
    position: relative;
    width: 80px;
    height: 80px;
    text-align: center;
    cursor: pointer;
    border: 1px dotted rgba(0, 0, 0, .1);
    background-color: rgba(0, 0, 0, .02);
    margin-right: 5px;
}

.filelist .img-actions {
    position: absolute;
    background: rgba($color: #000000, $alpha: 0.3);
    top: 1px;
    left: 3px;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    opacity: 0;
}

.filelist .img-actions>span {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.filelist .img-actions>span>i {
    color: #f00;
    font-size: 22px;
}

.attachment_imgBox {
    width: 80px;
    height: 80px;
    text-align: center;
    cursor: pointer;
    border: 1px dotted rgba(0, 0, 0, .1);
    background-color: rgba(0, 0, 0, .02);
}

.attachment_imgBox .el-icon-camera,
.attachment_imgBox .el-icon-video-camera,
.attachment_imgBox .el-icon-video-camera-solid {
    line-height: 80px;
    font-size: 20px;
    color: rgba(0, 0, 0, .3);
}

.filelist .attachmentImg {
    width: 74px;
    height: 74px;
    margin: 3px auto;
}

.attachment_imgBox .attachmentImg {
    width: 74px;
    height: 74px;
    margin: 3px auto;
}

.main-box {
    width: 100%;
    box-sizing: border-box;
    display: flex;
}

.main-file {
    display: block;
}
</style>