<template>
  <section>
    <slot :data="slotData" :options="optionsData">
      <template v-if="dataType == 'SINGLE'">
        <template v-for="item in optionsData">
          <el-tag :key="item[valueKey]" v-if="item[valueKey] == tValue">{{
            item[labelKey]
          }}</el-tag>
        </template>
      </template>
      <template v-if="dataType == 'ARR'">
        <div
          v-for="item in optionsData"
          :key="item[valueKey]"
          style="margin: 0 auto"
        >
          <p
            v-for="sitem in trimSpace(tValue)"
            :key="sitem"
            style="width: auto; float: left; margin-left: 5px"
          >
            <el-tag v-if="item[valueKey] == sitem">{{ item[labelKey] }}</el-tag>
          </p>
        </div>
      </template>
    </slot>
  </section>
</template>
<script>
import API from "@utils/request";
import { getDictionaryKeyVal } from "@/api/DataDictionary";
import { getDataDicttreeListKeyVal } from "@/api/DataDicttree";
import { Empty } from "element-ui";
export default {
  name: "TryDict",
  props: {
    valueKey: {
      // 列表中选项的值对应的key
      type: [String],
      default: "value",
    },
    labelKey: {
      // 列表中选项的标签对应的key
      type: String,
      default: "label",
    },
    dictName: {
      // 字典值
      type: String,
      default: "",
    },
    value: {
      // 绑定的值
      type: [Number, String],
      default: undefined,
    },

    size: {
      // medium/small/mini
      type: String,
      default: "medium",
    },

    options: {
      //数据源
      type: [Array, Object],
      default: undefined,
    },

    dataType: {
      //传入数据源类型
      type: String,
      default: "SINGLE",
    },
    url: {
      // 接口地址
      type: String,
      default: undefined,
    },
    parameData: {
      //Url带入参数
      type: [String, Number, Array],
      default: undefined,
    },
    isTreeData: {
      // 是否为DataTree数据源
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // loading: false,
      // tValue:
      //   this.dataType == "ARR"
      //     ? this.trimSpace(
      //         this.value == undefined || this.value == null
      //           ? ""
      //           : this.value.split(",")
      //       )
      //     : this.value,
      // optionsData: this.options,
      // slotData: "",
      loading: false,
      slotData: "",
      tValue: "",
      optionsData: this.options,
    };
  },
  watch: {
    // value(n, o) {
    //   if (n) {
    //     this.tValue = this.dataType == "ARR" ? this.trimSpace(n.split(",")) : n;
    //     this.initData();
    //   }
    // },
    async value(n, o) {
      if (n || n == 0) {
        await this.initval(n);
        this.initData();
      }
    },
    options(n, o) {
      this.optionsData = n;
      this.initData();
    },
  },
  // created() {
  //   this.requestData();
  // },
  async created() {
    await this.initval(this.value);
    await this.requestData();
    if (this.tValue) this.initData();
  },
  methods: {
    initval(value) {
      if (this.dataType == "ARR") {
        let val = [];
        if (value == undefined || value == null) val = [];
        if (value instanceof Array) val = value;
        if (typeof value == "string") val = value.split(",");
        this.tValue = this.trimSpace(val);
      } else {
        this.tValue = value;
      }
    },
    // 请求数据
    async requestData() {
      if (this.dictName) {
        this.loading = true;
        if (this.isTreeData) {
          let { data: res } = await getDataDicttreeListKeyVal(this.dictName);

          this.optionsData = res;
        } else {
          let { data: res } = await getDictionaryKeyVal(this.dictName);
          this.optionsData = res;
        }
        this.loading = false;
      } else if (this.url) {
        try {
          let { data: res } = await API.post(
            this.url,
            eval("(" + this.parameData + ")")
          );
          this.optionsData = res;
        } catch (error) {
          return error;
        }
      }
    },
    // 子组件值变化要通过父组件
    handleChange(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
    /**
     * 从数组中移除空值
     * @array {Array}
     */
    trimSpace(array) {
      for (var i = 0; i < array.length; i++) {
        //这里为过滤的值
        if (
          array[i] == "" ||
          array[i] == null ||
          typeof array[i] == "undefined" ||
          array[i] == ""
        ) {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      return array;
    },

    initData() {
      let labelKey = this.labelKey;
      let tValue = this.tValue;
      if (this.optionsData instanceof Array) {
        let valueKey = this.valueKey;
        let s_optionsData = this.trimSpace(tValue);
        if (this.dataType == "SINGLE") {
          this.optionsData.forEach((item) => {
            if (item[valueKey] == tValue) this.slotData = item[labelKey];
          });
        }
        if (this.dataType == "ARR") {
          let arr = [];
          this.optionsData.forEach((item) => {
            s_optionsData.forEach((sitem) => {
              if (item[valueKey] == sitem) arr.push(item[labelKey]);
            });
          });
          this.slotData = [...arr];
        }
      } else {
        if (this.dataType == "SINGLE") {
          if (this.optionsData[tValue]) {
            this.slotData = this.optionsData[tValue][labelKey];
          } else {
            this.slotData = "-";
          }
        }
        if (this.dataType == "ARR") {
          let arr = [];
          tValue.forEach((item) => {
            arr.push(this.optionsData[item][labelKey]);
          });
          this.slotData = [...arr];
        }
      }
    },
  },
};
</script>
