<template>
    <div v-loading="loading">
        <Editor :id="tinymceId" :init="init" :disabled="disabled" v-model="myValue"></Editor>
        <try-input v-model="myValue" style="display: none;"></try-input>
    </div>
</template>

<script>
//引入tinymce-vue
import Editor from "@tinymce/tinymce-vue";
import { getGroupItemValue } from "@api/AdminUser";
import { UPLOAD_IMG_URL, UPLOAD_FILE_URL, UPLOAD_FILE_SLICE, UPLOAD_FILE_MEDIA, UPLOAD_FILE_INPORT } from "@config";

const OSS = require('ali-oss');
import { getaliyunoss } from "@api/common";
import { addAttachment } from "@api/Attachment";

export default {
    name: "TryEditor",
    components: {
        Editor,
    },
    props: {
        //编号
        id: {
            type: String,
        },
        //内容
        value: {
            type: String,
            default: "",
        },
        //是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
        // 是否开启全功能
        allFunctions: {
            type: Boolean,
            default: () => {
                return true;
            },
        },
        // 高度 默认300
        height: {
            type: Number,
            default: () => {
                return 600;
            },
        },

        // 宽度 默认900
        width: {
            type: Number,
            default: () => {
                return 900;
            },
        },
        // 是否使用Oss直传
        isUseOss: {
            type: Boolean,
            default: false
        },
        // 需要二次上传oss的文件类型
        putTwoOssFileType: {
            type: Array,
            default: () => ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "GIF"],
        },
        keep_styles: true,
    },
    mounted: function () {
    },
    data() {
        let that = this;
        return {
            tinymceId: this.id || "vue-tinymce" + Date.parse(new Date()) + parseInt(Math.random() * 10000),
            myValue: this.value,
            init: {
                selector: this.tinymceId,
                entity_encoding: 'raw',
                //汉化,路径是自定义的，一般放在public或static里面，汉化文件要自己去下载
                language: "zh_CN",
                //皮肤
                skin: "oxide",
                convert_urls: false,
                automatic_uploads: true,
                images_upload_url: UPLOAD_IMG_URL + "?accesstoken=" + sessionStorage.getItem("token"),
                //隐藏技术支持
                branding: false,
                //隐藏底栏的元素路径
                elementpath: false,
                //关闭菜单
                menubar: this.allFunctions, // 菜单栏
                //隐藏状态栏
                statusbar: true,
                //高度
                height: this.height,
                // 超链接默认新界面打开
                default_link_target: "_blank",
                //公式插件
                external_plugins: {},

                buttonbar: "layoutButton",
                //插件
                plugins: this.allFunctions
                    ? "link image media upfile importword table lists fullscreen quickbars wordcount paste emoticons code autolink autosave imagetools paste preview code bdmap"
                    : "link image media upfile importword table lists quickbars wordcount paste emoticons code autolink autosave imagetools paste preview code bdmap",
                //工具栏
                toolbar1: this.allFunctions
                    ? "code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | fullscreen   indent2em lineheight formatpainter axupimgs | styleselect  fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | table image media upfile importword charmap  |  bdmap emoticons hr pagebreak insertdatetime print preview layoutButton "
                    : "undo redo| fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | image media  upfile importword | wordcount code ",

                toolbar_drawer: "sliding",
                quickbars_selection_toolbar:
                    "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor ",
                fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",
                quickbars_insert_toolbar: "image quicktable",
                style_formats: [
                    //新增 首行缩进与行高 配置
                    {
                        title: "首行缩进",
                        block: "p",
                        styles: {
                            "text-indent": "2em",
                        },
                    },

                    {
                        title: "段落间距",
                        items: [
                            {
                                title: "1",
                                styles: {
                                    "padding-bottom": "1em",
                                },
                                block: "p",
                            },
                            {
                                title: "1.5",
                                styles: {
                                    "padding-bottom": "1.5em",
                                },
                                block: "p",
                            },
                            {
                                title: "2",
                                styles: {
                                    "padding-bottom": "2em",
                                },
                                block: "p",
                            },
                            {
                                title: "2.5",
                                styles: {
                                    "padding-bottom": "2.5em",
                                },
                                block: "p",
                            },
                            {
                                title: "3",
                                styles: {
                                    "padding-bottom": "3em",
                                },
                                block: "p",
                            },
                        ],
                    },

                    //默认的配置
                    {
                        title: "Headings",
                        items: [
                            {
                                title: "Heading 1",
                                format: "h1",
                            },
                            {
                                title: "Heading 2",
                                format: "h2",
                            },
                            {
                                title: "Heading 3",
                                format: "h3",
                            },
                            {
                                title: "Heading 4",
                                format: "h4",
                            },
                            {
                                title: "Heading 5",
                                format: "h5",
                            },
                            {
                                title: "Heading 6",
                                format: "h6",
                            },
                        ],
                    },
                    {
                        title: "Inline",
                        items: [
                            {
                                title: "Bold",
                                icon: "bold",
                                format: "bold",
                            },
                            {
                                title: "Italic",
                                icon: "italic",
                                format: "italic",
                            },
                            {
                                title: "Underline",
                                icon: "underline",
                                format: "underline",
                            },
                            {
                                title: "Strikethrough",
                                icon: "strikethrough",
                                format: "strikethrough",
                            },
                            {
                                title: "Superscript",
                                icon: "superscript",
                                format: "superscript",
                            },
                            {
                                title: "Subscript",
                                icon: "subscript",
                                format: "subscript",
                            },
                            {
                                title: "Code",
                                icon: "code",
                                format: "code",
                            },
                        ],
                    },
                    {
                        title: "Blocks",
                        items: [
                            {
                                title: "Paragraph",
                                format: "p",
                            },
                            {
                                title: "Blockquote",
                                format: "blockquote",
                            },
                            {
                                title: "Div",
                                format: "div",
                            },
                            {
                                title: "Pre",
                                format: "pre",
                            },
                        ],
                    },
                    {
                        title: "Alignment",
                        items: [
                            {
                                title: "Left",
                                icon: "alignleft",
                                format: "alignleft",
                            },
                            {
                                title: "Center",
                                icon: "aligncenter",
                                format: "aligncenter",
                            },
                            {
                                title: "Right",
                                icon: "alignright",
                                format: "alignright",
                            },
                            {
                                title: "Justify",
                                icon: "alignjustify",
                                format: "alignjustify",
                            },
                        ],
                    },
                ],

                //粘贴data格式的图像
                paste_data_images: true,
                width: this.width,
                // images_dataimg_filter: function (img) {
                //   return img.hasAttribute('internal-blob');
                // },
                images_upload_handler: (blobInfo, success, failure, progress) => {
                    // 方法1：用base64的图片形式上传图片
                    // const img = 'data:image/jpeg;base64,' + blobInfo.base64()
                    // success(img)

                    // 方法2：上传
                    const xhr = new XMLHttpRequest();
                    xhr.withCredentials = false;
                    xhr.open("POST", UPLOAD_IMG_URL);
                    xhr.setRequestHeader("accesstoken", sessionStorage.getItem("token"));
                    xhr.upload.onprogress = function (e) {
                        progress((e.loaded / e.total) * 100);
                    };
                    // 成功结果
                    xhr.onload = function () {
                        if (xhr.status != 200) {
                            failure("HTTP Error: " + xhr.status, { remove: true });
                            return;
                        }
                        const json = JSON.parse(xhr.responseText);
                        if (json.status != 200) {
                            failure("图像上传失败:" + json.msg);
                            return;
                        }
                        success(json.data.address);
                    };
                    // 失败结果
                    xhr.onerror = function () {
                        failure("图像上传失败:" + xhr.status);
                    };
                    // 请求数据
                    const formData = new FormData();
                    formData.append("file", blobInfo.blob(), blobInfo.filename());
                    xhr.send(formData);
                },
                file_picker_callback: (callback, value, meta) => {
                    let _this = this;
                    //文件分类
                    var filetype =
                        ".pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4";
                    //后端接收上传文件的地址
                    var upurl = UPLOAD_FILE_URL;

                    //为不同插件指定文件类型及后端地址
                    switch (meta.filetype) {
                        case "image":
                            filetype = ".jpg, .jpeg, .png, .gif";
                            upurl = UPLOAD_IMG_URL;
                            break;
                        case "media":
                            filetype = ".mp3, .mp4";
                            upurl = UPLOAD_FILE_MEDIA;
                            break;
                        case "upfile":
                            var filetype =
                                ".pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4";
                            upurl = UPLOAD_FILE_URL;
                        default:
                    }
                    //模拟出一个input用于添加本地文件
                    var input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", filetype);
                    input.click();
                    input.onchange = function () {
                        var file = this.files[0];
                        if (that.isUseOss) {
                            const _name = that.getDate() + '/' + that.generateCustomKey() + '.' + file.name.split('.')[file.name.split('.').length - 1];
                            /** 第二次上传oss */
                            // 提取文件扩展名
                            let fileExtension = (file.name).slice(file.name.lastIndexOf(".") + 1);
                            // 检查文件类型是否符合要求
                            const isTypeOk = that.putTwoOssFileType.some((type) => {
                                // 判断文件扩展名是否包含允许的类型之一
                                if (fileExtension.indexOf(type) > -1) return true;
                                if (fileExtension && fileExtension.indexOf(type) > -1) return true;
                                return false;
                            });
                            // 如果文件类型符合要求且文件存在，则二次上传文件
                            if (isTypeOk && file) {
                                that.putTwoOss(_name, file);
                            }

                            /** 第一次上传oss */
                            that.putOss(_name, file).then(async (res) => {
                                if (res) {
                                    that.getOssUrl(res.name).then(async (rel) => {
                                        const url = new URL(rel);
                                        const requestUrls = url.origin + url.pathname;
                                        let tempItem = {
                                            att_title: file.name,
                                            att_url: requestUrls
                                        };
                                        /** 获取入库文件attid */
                                        let att_data = await that.addAttachment(tempItem);
                                        let att_url = att_data.att_url;
                                        // 组装扔回去 requestUrls绝对路径 att_url相对路径
                                        callback(att_url, { title: file.name });
                                    })
                                } else {
                                    this.$message.error("前端直传OSS失败");
                                    return false;
                                }
                            })
                        } else {
                            var xhr, formData;
                            xhr = new XMLHttpRequest();

                            xhr.withCredentials = false;
                            xhr.open("POST", upurl);
                            xhr.setRequestHeader(
                                "accesstoken",
                                sessionStorage.getItem("token")
                            );
                            xhr.onload = function () {
                                if (xhr.status != 200) {
                                    alert("文件上传失败：" + xhr.status);

                                    return;
                                }
                                const json = JSON.parse(xhr.responseText);
                                if (json.status != 200) {
                                    alert("文件上传失败：" + json.msg);
                                    return;
                                }
                                //请求成功
                                if (json.status == 200) {
                                    // 组装扔回去
                                    callback(json.data.address, { title: file.name });
                                }
                            };


                            formData = new FormData();
                            formData.append("file", file, file.name);
                            xhr.send(formData);
                        }
                    };
                },
                setup: function (edt) {

                    edt.ui.registry.addButton("layoutButton", {
                        text: "一键排版",
                        onAction: function (_) {
                            var txt = edt.getContent();

                            //清除所有html空格
                            txt = txt.replace(/&nbsp;/gi, "");

                            //清除所有空行（摘录网上，实测可用）
                            var arrPattern = [
                                "<p(>|\\s+[^>]*>)(&nbsp|&nbsp;|\\s|　|<br\\s*(/)?>)*</p(>|\\s+[^>]*>)",
                                "(<br\\s*(/)?>((\\s|&nbsp;|&nbsp|　)*)){2,}",
                                "(<p(>|\\s+[^>]*>))((&nbsp|&nbsp;|\\s)*<br\\s*(/)?>)*((.|\n|\r)*?</p(>|\\s+[^>]*>))",
                            ];
                            var arrReplace = ["", "<br />$3", "$1$6"];
                            for (var i = 0; i < arrPattern.length; i++) {
                                var arrRegExp = new RegExp(arrPattern[i], "img");
                                txt = txt.replace(arrRegExp, arrReplace[i]);
                            }

                            //清除所有非空格的空白字符，空格字符去除可能导致元素属性出错
                            txt = txt.replace(/[\f\n\r\t\v]/gi, "");

                            //清除所有span
                            txt = txt.replace(/<(\/span|span).*?>/gi, "");

                            //清除超链接，将网址分离在后
                            // txt = txt.replace(
                            //     /<a.*?href\s*=\s*[\"|\'](.*?)[\"|\'].*?>(.*?)<\/a>/gi,
                            //     "$2[网址：$1]"
                            // );
                            // //实际运行中发现有些错误的a链接可能根本没有href，所以需要再次清理
                            // txt = txt.replace(/<(\/a|a).*?>/gi, "");

                            //清除所有class，为防止有些class不加引号，因此强制规定只清除元素内的class
                            txt = txt.replace(/class\s*?=\s*?([‘"])[\s\S]*?\1/gi, "");

                            //清除所有style属性
                            //直接量语法：
                            txt = txt.replace(/style\s*?=\s*?([\'\"])[\s\S]*?\1/gi, "");
                            //RegExp对象语法，可用于自定义变量，比如出了style以外的class，lang等等
                            var v = "style";
                            txt = txt.replace(
                                new RegExp(v + "\\s*?=\\s*?(['\"])[\\s\\S]*?\\1", "ig"),
                                ""
                            );

                            // //清除所有元素属性，超链接可能被清除
                            // txt = txt.replace(/<([a-zA-Z1-6]+)(\s*[^>]*)?>/ig, "<$1>");

                            //如果保留img,p,table标签，则为：
                            txt = txt.replace(
                                /<(?!table|tr|td|a|h1|h2|h3|h4|h5|h6|\/table|\/tr|\/a|\/h1|\/h2|\/h3|\/h4|\/h5|\/h6|\/td|img|p|\/p).*?>/g,
                                ""
                            );
                            //给每段落加上样式
                            txt = txt.replace(/<p.*?>/gi, '<p style="text-indent:2em;">');
                            //给图片加上居中样式
                            txt = txt.replace(
                                /<img/gi,
                                '<img style="margin: 0px auto; display: block;" '
                            );
                            //给表格加上居中样式
                            txt = txt.replace(
                                /<table/gi,
                                '<table style="border-collapse: collapse; margin-left: auto; margin-right: auto;"   border="1" '
                            );

                            edt.setContent(txt);
                        },
                    });
                },
                importword_handler: function (editor, files, next) {
                    var file_name = files[0].name;
                    if (file_name.substr(file_name.lastIndexOf(".") + 1) == "docx") {
                        editor.notificationManager.open({
                            text: "正在转换中...",
                            type: "info",
                            closeButton: false,
                        });
                        next(files);
                    } else {
                        editor.notificationManager.open({
                            text: "目前仅支持docx文件格式，若为doc，请将扩展名改为docx",
                            type: "warning",
                        });
                    }
                    // next(files);
                },
                importword_filter: function (result, insert, message) {
                    // 自定义操作部分
                    insert(result); //回插函数
                },

                file_callback: (fileInfo, callback) => {
                    const xhr = new XMLHttpRequest();
                    xhr.withCredentials = false;
                    xhr.open("POST", UPLOAD_FILE_URL);
                    xhr.setRequestHeader("accesstoken", sessionStorage.getItem("token"));
                    // 成功结果
                    xhr.onload = function () {
                        if (xhr.status != 200) {
                            alert("HTTP Error: " + xhr.status, { remove: true });
                            return;
                        }
                        const json = JSON.parse(xhr.responseText);
                        if (json.status != 200) {
                            alert("文件上传失败：" + json.msg);
                            return;
                        }
                        callback(json.data.address, { title: fileInfo.name });
                    };
                    // 失败结果
                    xhr.onerror = function () {
                        alert("文件上传失败：" + xhr.status);
                    };
                    // 请求数据
                    const formData = new FormData();
                    formData.append("file", fileInfo, fileInfo.name);
                    xhr.send(formData);
                },

            },

            loading: false,
            loadingtext: '上传中',

        };
    },
    created() { },
    methods: {
        /** 上传第二个oss */
        async putTwoOss(filename, file) {
            console.log("shagnchuanzhng")
            let res = await getaliyunoss();
            let ossConfig = res.data;
            const client = new OSS({
                region: ossConfig.region2 ? ossConfig.region2 : "",
                accessKeyId: ossConfig.accessKeyId,
                accessKeySecret: ossConfig.accessKeySecret,
                stsToken: ossConfig.securityToken,
                bucket: ossConfig.bucket2 ? ossConfig.bucket2 : "", // 第二个oss的bucket自行替换
            });
            let fileurl = ossConfig.img_path + filename;
            const headers = {
                // 指定该Object被下载时的内容编码格式。
                "Content-Encoding": "utf-8",
                'Access-Control-Allow-Origin': '*',
            };
            const options = {
                headers: headers,
                // 获取分片上传进度、断点和返回值。
                progress: (p, cpt, res) => {
                    this.loading = false;
                },
                // 设置并发上传的分片数量。
                parallel: 4,
                // 设置分片大小。默认值为1 MB，最小值为100 KB。
                partSize: 1024 * 1024,
                // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
                meta: { year: 2020, people: "test" },
                mime: "text/plain",
                timeout: 600000,//设置超时时间
            };
            const result = await client.multipartUpload(fileurl, file, options);
            return true;
        },
        /** 文件入附件库 */
        async addAttachment(data) {
            data.att_storage = 2;
            const { data: resdata } = await addAttachment(data);
            return resdata;
        },
        // 获取当前年月日
        getDate() {
            const date = new Date()
            var year = date.getFullYear()
            var month = date.getMonth() + 1
            var day = date.getDate()
            month = month > 9 ? month : '0' + month
            day = day < 10 ? '0' + day : day
            var today = year + month + day
            return today
        },
        /**
         * 根据时间生成唯一键
         */
        generateCustomKey() {
            const datePart = new Date().toISOString().replace(/[-:.TZ]/g, '').slice(0, 14); // 获取当前时间的特定格式
            const randomPart = Math.random().toString(36).substring(2, 10); // 生成随机字符串
            return datePart + randomPart;
        },
        /** 提交数据到oss获取oss返回数据 */
        async putOss(fileName, file) {
            this.loadingtext = '上传中...';
            var loading = this.$loading({
                lock: false,
                text: this.loadingtext,
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });

            try {
                let res = await getaliyunoss();
                let ossConfig = res.data;
                const client = new OSS({
                    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                    region: ossConfig.region,
                    accessKeyId: ossConfig.accessKeyId,
                    accessKeySecret: ossConfig.accessKeySecret,
                    stsToken: ossConfig.securityToken,
                    bucket: ossConfig.bucket,
                });
                let fileurl = ossConfig.img_path + fileName;
                const headers = {
                    // 指定该Object被下载时的网页缓存行为。
                    // "Cache-Control": "no-cache",
                    // 指定该Object被下载时的名称。
                    // "Content-Disposition": "example.txt",
                    // 指定该Object被下载时的内容编码格式。
                    "Content-Encoding": "utf-8",
                    // 指定过期时间，单位为毫秒。
                    // Expires: "1000",
                    // 指定Object的存储类型。
                    // "x-oss-storage-class": "Standard",
                    // 指定Object标签，可同时设置多个标签。
                    // "x-oss-tagging": "Tag1=1&Tag2=2",
                    // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
                    // "x-oss-forbid-overwrite": "true",

                    'Access-Control-Allow-Origin': '*',
                };
                const options = {
                    headers: headers,
                    // 获取分片上传进度、断点和返回值。
                    progress: (p, cpt, res) => {

                        this.loadingtext = "上传中..." + parseInt(p * 100) + "%";
                        // console.log(this.loadingtext,'>>>>>>>>>>>>>>>>>>>>>>');
                        loading.text = this.loadingtext;
                        // loading = this.$loading({
                        //     lock: false,
                        //     text: this.loadingtext,
                        //     spinner: 'el-icon-loading',
                        //     background: 'rgba(255, 255, 255, 0.7)'
                        // });
                    },
                    // 设置并发上传的分片数量。
                    parallel: 4,
                    // 设置分片大小。默认值为1 MB，最小值为100 KB。
                    partSize: 1024 * 1024,
                    // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
                    meta: { year: 2020, people: "test" },
                    mime: "text/plain",
                    timeout: 600000,//设置超时时间
                };
                const result = await client.multipartUpload(fileurl, file, options);
                // console.log("结束上传00-----------")
                this.loading = false;

                // this.loading = false;
                loading.close();
                return result;

            } catch (e) {
                console.log(e);
            }
        },
        async getOssUrl(name) {
            try {
                let res = await getaliyunoss();
                let ossConfig = res.data;
                const client = new OSS({
                    region: ossConfig.region,
                    accessKeyId: ossConfig.accessKeyId,
                    accessKeySecret: ossConfig.accessKeySecret,
                    stsToken: ossConfig.securityToken,
                    bucket: ossConfig.bucket,
                });
                const result = await client.signatureUrl(name);
                return result;
            } catch (e) {
                console.log(e);
            }
        },
    },

    watch: {
        //监听内容变化
        value(newValue) {
            this.myValue = newValue;
        },
        myValue(newValue) {
            this.$emit("input", newValue);
        },
    },
};
</script>
<style>
.tox-notifications-container {
    display: none;
}

.tox-tinymce-aux {
    z-index: 5000 !important;
}

.tox-dialog {
    background-color: aqua;
}

.el-loading-mask {
    z-index: 10000 !important;
}
</style>