/* 布局 */
import Layout from "@views/layout";

const systemRouter = {
  path: "/system",
  name: "SYSTEM",
  component: Layout,
  redirect: { name: "USER" },
  meta: {
    title: "系统管理",
  },
  children: [
    {
      path: "user",
      name: "USER",
      meta: {
        title: "管理员管理",
        keepAlive:true
      },
      component: () => import("@views/system/admin/user/index.vue"),
    },
    {
      path: "role",
      name: "ROLE",
      meta: {
        title: "角色管理",
        keepAlive:true
      },
      component: () => import("@views/system/admin/role/index.vue"),
    },
    {
      path: "menu",
      name: "MENU",
      meta: {
        title: "菜单管理",
        keepAlive:true
      },
      component: () => import("@views/system/admin/menu/index.vue"),
    },
    {
      path: "department",
      name: "DEPARTMENT",
      meta: {
        title: "部门管理",
        keepAlive:true
      },
      component: () => import("@views/system/admin/department/index.vue"),
    },
    {
      path: "job",
      name: "JOB",
      meta: {
        title: "岗位管理",
        keepAlive:true
      },
      component: () => import("@views/system/admin/job/index.vue"),
    },
    {
      path: "authority",
      name: "AUTHORITY",
      meta: {
        title: "权限管理",
        keepAlive:true
      },
      component: () => import("@views/system/admin/auth/index.vue"),
    },

    {
      path: "notice",
      name: "ADMINNOTICE",
      meta: {
        title: "公告管理",
        keepAlive:true
      },
      component: () => import("@views/system/admin/notice/index.vue"),
    },
    {
      path: "dynamics",
      name: "ADMINDYNAMICS",
      meta: {
        title: "系统动态管理",
        keepAlive:true
      },
      component: () => import("@views/system/admin/dynamics/index.vue"),
    },

    {
      path: "dictionary",
      name: "DICTIONARY",
      alwaysShow: true,
      meta: {
        title: "字典管理",
        keepAlive:false
      },
      component: () => import("@views/system/data/dictionary/index.vue"),
    },
    {
      path: "dicttree",
      name: "DICTTREE",
      alwaysShow: true,
      meta: {
        title: "树形字典",
        keepAlive:false
      },
      component: () => import("@views/system/data/dicttree/index.vue"),
    },
    {
      path: "group",
      name: "GROUP",
      meta: {
        title: "配置管理",
        keepAlive:false
      },
      component: () => import("@views/system/data/group/index.vue"),
    },
    {
      path: "district",
      name: "DISTRICT",
      meta: {
        title: "城市管理",
        keepAlive:true
      },
      component: () => import("@views/system/data/district/index.vue"),
    },
    {
      path: "log",
      name: "LOG",
      meta: {
        title: "日志管理",
        keepAlive:true
      },
      component: () => import("@views/system/admin/log/index.vue"),
    },
    {
      path: "attachment",
      name: "ATTACHMENT",
      meta: {
        title: "附件管理",
        keepAlive:true
      },
      component: () => import("@views/system/admin/attachment/index.vue"),
    }
  ],
};

export default systemRouter;
