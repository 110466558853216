<style lang="scss" scoped>
.messagesbox {
  display: inline-block;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  padding: 0 4px;
  box-sizing: border-box;
  border: 1px solid #fff;
  background: #E02128 !important;
  font-size: 12px;
  color: #ffffff !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: scale(0.8) translateY(-50%);
}

::v-deep {
  .el-badge__content {
    background-color: #E02128 !important;
  }
}
</style>
<template>
  <!-- 有子集 -->
  <el-submenu v-if="item.children.length > 0" :index="item.amenu_id + ''">
    <template slot="title">
      <i :class="item.amenu_icon"></i>
      <span slot="title">{{ item.amenu_name }}
        <el-badge v-if="parentmessag.indexOf(item.amenu_id) > -1  && messagearr[item.amenu_id]>0" is-dot />
        <!-- <i class="messagesbox" v-if="parentmessag.indexOf(item.amenu_id) > -1"></i> -->
      </span>
    </template>
    <el-menu-item-group>
      <SidebarGroup v-for="subItem in item.children" :key="subItem.id" :item="subItem" :messages="messages" />
    </el-menu-item-group>
  </el-submenu>

  <!-- 无子集 -->
  <el-menu-item class="no-child" :index="item.amenu_address + ''" v-else>
    <el-tooltip class="item" effect="dark" :content="item.amenu_name" placement="right" v-if="isCollapse">
      <!-- span是button的外层标签 -->
      <i :class="item.amenu_icon"></i>
    </el-tooltip>
    <i :class="item.amenu_icon" v-else></i>
    {{ isCollapse == false ? item.amenu_name : "" }}
    <i class="messagesbox" v-if="messagea.indexOf(item.amenu_id) > -1 && messageattr[item.amenu_id]>0">{{ messageattr[item.amenu_id] }}</i>
  </el-menu-item>
</template>

<script>

export default {
  name: "SidebarGroup",
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    },
    item: {
      type: [Array, Object],
      default: ""
    },
    messages: {
      type: [Array, Object],
      default: {}
    }
  },
  data() {
    return {

      messagess: this.messages,

      //  子类
      messagea: [
        // 190,
        175,
        187,
        181
      ],
      //  子类
      messageattr: {},

      parentmessag: [
        195,
        196,
        197
      ],

      // 父类
      messagearr: {}

    };
  },


  watch: {
    messages(newValue) {
      this.setmessage();
    }
  },
  methods: {

    // 初始化 消息
    setmessage() {
      this.messageattr = {
        // 190: this.messages.act_sign_up_dealt_num, 

        175: this.messages.act_dealt_num,
        187: this.messages.topic_article_dealt_num,
        181: this.messages.post_dealt_num
      };
      this.messagearr = {
        // 195: this.messages.act_sign_up_dealt_num,

        195: this.messages.act_dealt_num,
        196: this.messages.topic_article_dealt_num,
        197: this.messages.post_dealt_num,
      }
    },
    //点击跳转
    clickMenu(item) {
      this.$router.push({
        name: item.name,
        title: item.name
      });

      //设置tags导航
      this.$store.commit("selectMent", item)
    },
  },

  mounted() {
    this.setmessage();
  }
};
</script>