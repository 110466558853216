/* 布局 */
//前台
import Layout from "@views/layout";

const memberRouter = {
    path: "/demo",
    component: Layout,
    redirect: {
        name: "DEMO"
    },
    children: [
        {
            path: "print",
            name: "PRINT",
            meta: {
                title: "打印Demo",
                keepAlive:true
            },
            component: () => import("@views/demo/print.vue"),
        },
    ],
};

export default memberRouter;
