var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"upload-oc",style:({ height: (_vm.listType === 'picture' && '100%') || 'auto' })},[_c('div',{staticClass:"upload-box"},[(!_vm.isUseOss)?_c('el-upload',{ref:"UplaodFile",staticClass:"upload-demo",class:{
        nborder: _vm.fileList.length && _vm.listType === 'picture',
      },attrs:{"action":_vm.uploadUrl,"data":_vm.uploadData,"before-upload":_vm.handleBeforeUpload,"on-success":_vm.handleSuccess,"on-error":_vm.handleUploadError,"on-remove":_vm.handleRemove,"on-exceed":_vm.handleExceed,"file-list":_vm.fileList,"multiple":_vm.upOption.fileLimit > 1,"headers":_vm.paramsData,"limit":_vm.upOption.fileLimit,"list-type":_vm.listType,"drag":_vm.listType === 'picture',"disabled":_vm.disabled || _vm.loading,"show-file-list":_vm.showFileList}},[(_vm.listType === 'picture')?[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"img-single"},[(!_vm.fileList.length)?[_c('i',{staticClass:"el-icon-upload"}),_c('p',{staticClass:"el-upload__text"},[_vm._v("将图片拖到此处，或"),_c('em',[_vm._v("点击上传")])])]:[_c('el-image',{attrs:{"src":_vm.fileList[0].url,"fit":"cover"},on:{"load":function($event){_vm.loading = false},"error":function($event){_vm.loading = false}}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('font',{staticClass:"icon-tupianshibai"})],1)])]],2)]:_vm._e(),(_vm.listType === 'text' || _vm.listType === 'picture-card')?[_c('div',{staticClass:"upload-control"},[_c('el-button',{attrs:{"slot":"trigger","size":"small","type":"primary"},slot:"trigger"},[_vm._v(" "+_vm._s(_vm.btntext)+" ")]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},on:{"click":function($event){$event.stopPropagation();}},slot:"tip"},[_c('p',{staticClass:"tip-text"},[_c('font',{staticClass:"icon-xinxi"}),_vm._t("suptip"),_vm._v("支持上传"+_vm._s(_vm.fileTypeName || "jpg/png")+"文件， 且不能超过 "+_vm._s(_vm.upOption.fileSize)+"MB ")],2)])],1),_c('div',{staticClass:"trigger-mask",on:{"click":function($event){$event.stopPropagation();}}})]:_vm._e()],2):_c('el-upload',{ref:"uploadOss",staticClass:"upload-demo",class:{
        nborder: _vm.fileList.length && _vm.listType === 'picture',
      },style:({'cursor': (_vm.disabled || _vm.loading) ? 'default' : 'pointer'}),attrs:{"action":_vm.uploadUrl,"data":_vm.uploadData,"before-upload":_vm.handleBeforeUpload,"on-success":_vm.handleSuccess,"on-error":_vm.handleUploadError,"on-remove":_vm.handleOssRemove,"on-exceed":_vm.handleExceed,"file-list":_vm.fileList,"multiple":_vm.upOption.fileLimit > 1,"limit":_vm.upOption.fileLimit,"list-type":_vm.listType,"drag":_vm.listType === 'picture',"disabled":_vm.disabled || _vm.loading,"http-request":_vm.handleUploadFile,"show-file-list":_vm.showFileList}},[(_vm.listType === 'picture')?[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"img-single",style:({'cursor': (_vm.disabled || _vm.loading) ? 'default' : 'pointer'})},[(!_vm.fileList.length)?[_c('i',{staticClass:"el-icon-upload"}),_c('p',{staticClass:"el-upload__text"},[_vm._v("将图片拖到此处，或"),_c('em',[_vm._v("点击上传")])])]:[_c('el-image',{attrs:{"src":_vm.fileList[0].url,"fit":"cover"},on:{"load":function($event){_vm.loading = false},"error":function($event){_vm.loading = false}}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('font',{staticClass:"icon-tupianshibai"})],1)])]],2)]:_vm._e(),(_vm.listType === 'text' || _vm.listType === 'picture-card')?[_c('div',{staticClass:"upload-control"},[_c('el-button',{attrs:{"slot":"trigger","size":"small","type":"primary"},slot:"trigger"},[_vm._v(" "+_vm._s(_vm.btntext)+" ")]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},on:{"click":function($event){$event.stopPropagation();}},slot:"tip"},[_c('p',{staticClass:"tip-text"},[_c('font',{staticClass:"icon-xinxi"}),_vm._t("suptip"),_vm._v("支持上传"+_vm._s(_vm.fileTypeName || "jpg/png")+"文件， 且不能超过 "+_vm._s(_vm.upOption.fileSize)+"MB ")],2)])],1),_c('div',{staticClass:"trigger-mask",on:{"click":function($event){$event.stopPropagation();}}})]:_vm._e()],2),(
        !_vm.showFileList && (_vm.listType === 'text' || _vm.listType === 'picture-card')
      )?[(_vm.exhibitFile.length)?_c('ul',{staticClass:"file-list"},_vm._l((_vm.exhibitFile),function(item){return _c('li',{key:item.uid,class:_vm.file_status(item)},[_c('span',{staticClass:"file-status"}),_c('p',[_vm._v(_vm._s(item.name))]),_c('font',{staticClass:"icon-shanchu",on:{"click":function($event){return _vm.delExhibitFile(item)}}})],1)}),0):_vm._e(),(_vm.file_loading)?_c('p',{staticClass:"loading-text"},[_vm._v(" "+_vm._s(_vm.loadingText)+" ")]):_vm._e()]:_vm._e(),(_vm.showImgTips && _vm.listType === 'picture')?_c('div',{staticClass:"img-single-tips"},[_c('font',{staticClass:"icon-xinxi"}),_c('span',[_vm._t("suptip"),_vm._v("格式"+_vm._s(_vm.fileTypeName || "jpg/png")+"， 大小不超过"+_vm._s(_vm.upOption.fileSize)+"MB ")],2)],1):_vm._e()],2),_c('el-dialog',{attrs:{"title":"图片裁剪","visible":_vm.dialogVisible,"modal":false,"width":"504px","close-on-click-modal":false,"close-on-press-escape":false,"before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('Vuecopper',{attrs:{"option":_vm.option},on:{"cropper-request":_vm.handleUploadFile,"cropper-closerequest":_vm.handleCropperClose}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }