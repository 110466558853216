<style lang="scss" scoped>
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// .images-list {
//   border: 1px dashed #d5d5d5;
//   padding: 10px;
//   border-radius: 4px;
//   background: #fff;
// }
.upload-oc {
  width: 100%;
  .upload-box,
  .upload-demo,
  .img-single {
    width: 100%;
    height: 100%;
  }
  .upload-box {
    display: flex;
    flex-direction: column;
    .upload-demo {
      flex: 1 1 0%;
    }
  }
  .img-single {
    min-width: 400px;
    min-height: 188px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .el-icon-upload {
      margin-top: 0;
      margin-bottom: 28px;
    }
    .el-upload__text {
      font-size: 14px;
      line-height: 22px;
      color: #aeaeae;
      em {
        color: #0b6ad2;
      }
    }
    .el-image:hover {
      position: relative;
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(17, 17, 17, 0.22);
      }
      &::after {
        content: "更换图片";
        width: 92px;
        height: 36px;
        border-radius: 40px;
        background: rgba(255, 255, 255, 0.36);
        color: #fff;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 36px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &-tips {
      min-width: 100%;
      width: auto;
      height: 48px;
      margin: 10px 0 0;
      border-radius: 4px;
      background: #f5f5f5;
      text-align: left;
      box-sizing: border-box;
      padding: 0 16px;
      font-size: 14px;
      line-height: 48px;
      color: #191919;
      display: flex;
      align-items: center;
      font {
        width: 18px;
        height: 100%;
        font-size: 18px;
        color: #2070f3;
        margin-right: 9px;
      }
      span {
        white-space: nowrap;
        border-radius: 4px;
        background: #f5f5f5;
        padding-right: 16px;
      }
    }
  }
  .upload-control {
    width: calc(((100% - 64px) / 3) * 2 + 32px);
    max-width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    .el-button {
      width: 116px;
      height: 32px;
      border-radius: 4px;
      border-color: #0067d1;
      background: #0067d1;
    }
    .el-upload__tip {
      flex: 1 1 0%;
      height: 32px;
      box-sizing: border-box;
      padding-left: 12px;
      margin: 0;
      .tip-text {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background: #eef3fe;
        text-align: left;
        box-sizing: border-box;
        padding: 0 8px;
        font-size: 12px;
        line-height: 32px;
        color: #191919;
      }
      font {
        width: 14px;
        height: 100%;
        font-size: 14px;
        color: #2070f3;
        margin-right: 5px;
      }
    }
  }
  .loading-text {
    font-size: 12px;
    line-height: 20px;
    margin-top: 4px;
    color: #0b6ad2;
  }
}
.file-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2px;
  li {
    list-style: none;
    width: calc((100% - 64px) / 3);
    height: 32px;
    border-radius: 4px;
    background: #f5f6f8;
    margin-top: 8px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 8px;
    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 16px;
      height: 16px;
    }
    p {
      flex: 1 1 0%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      line-height: 20px;
      color: #191919;
      margin: 0 8px;
    }
    .icon-shanchu {
      width: 32px;
      height: 32px;
      font-size: 14px;
      color: #191919;
      margin-right: -8px;
      margin-left: -8px;
      cursor: pointer;
      z-index: 1;
      position: relative;
      transition: all 0.3s;
      &:hover {
        color: #0368d1;
      }
    }
    &.loading {
      span::before {
        content: "";
        border: 2px dotted #0368d1;
        border-style: solid dotted dotted solid;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        -webkit-animation: rotation 2s linear infinite;
        animation: rotation 2s linear infinite;
        transform-origin: center center;
      }
      .icon-shanchu {
        display: none;
      }
    }
    &.success {
      span::before {
        content: "\e8ad";
        font-size: 16px;
        color: #0368d1;
        font-family: "icon" !important;
      }
    }
    &.fail {
      span::before {
        content: "\e8b8";
        font-size: 16px;
        color: #e02128;
        font-family: "icon" !important;
      }
    }
  }
  &::after {
    content: "";
    width: calc((100% - 64px) / 3);
  }
}
::v-deep {
  .el-upload {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;

    .trigger-mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      cursor: default;
    }
    &-dragger {
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      &:hover {
        border-color: #0067d1;
      }
    }
    &--picture-card {
      border: none;
      background: transparent;
    }
    // &-list {
    //   display: flex;
    //   flex-wrap: wrap;
    //   margin-top: 10px;
    //   &::after {
    //     content: "";
    //     width: calc((100% - 64px) / 3);
    //   }
    //   &__item {
    //     width: calc((100% - 64px) / 3);
    //     height: 32px;
    //     border-radius: 4px;
    //     background: #f5f6f8;
    //     margin-top: 8px;
    //     display: flex;
    //     align-items: center;
    //     margin-right: 32px;
    //     &:nth-of-type(3n) {
    //       margin-right: 0;
    //     }
    //     &-name {
    //       flex: 1 1 0%;
    //       font-size: 12px;
    //       color: #191919;
    //       margin-right: 0;
    //       position: relative;
    //       padding-left: 32px;
    //       height: 32px;
    //       line-height: 32px;
    //       .el-icon-document {
    //         width: 16px;
    //         height: 16px;
    //         margin-right: 0;
    //         display: inline-flex;
    //         align-items: center;
    //         justify-content: center;
    //         position: absolute;
    //         top: 8px;
    //         left: 8px;
    //       }
    //     }
    //     &-status-label,
    //     .el-icon-close-tip {
    //       display: none !important;
    //     }
    //     .el-icon-close {
    //       display: inline-flex;
    //       align-items: center;
    //       justify-content: center;
    //       width: 30px;
    //       height: 100%;
    //       color: #191919;
    //       font-size: 14px;
    //       position: static;
    //       &::before {
    //         content: "\e852";
    //         font-family: "icon" !important;
    //       }
    //     }
    //     &.is-uploading,
    //     &.el-list-enter-to {
    //       .el-icon-document::before {
    //         content: "";
    //         border: 2px dotted #0368d1;
    //         border-style: solid dotted dotted solid;
    //         width: 16px;
    //         height: 16px;
    //         border-radius: 50%;
    //         -webkit-animation: rotation 2s linear infinite;
    //         animation: rotation 2s linear infinite;
    //         transform-origin: center center;
    //       }
    //       .el-icon-close {
    //         display: none;
    //       }
    //     }
    //     &.is-success:not(.el-list-enter-to) {
    //       .el-icon-document::before {
    //         content: "\e8ad";
    //         font-size: 16px;
    //         color: #0368d1;
    //         font-family: "icon" !important;
    //       }
    //     }
    //     &.is-fail:not(.el-list-enter-to) {
    //       .el-icon-document::before {
    //         content: "\e8b8";
    //         font-size: 16px;
    //         color: #e02128;
    //         font-family: "icon" !important;
    //       }
    //     }
    //   }
    // }
  }

  .nborder {
    .el-upload-dragger {
      border-color: transparent;
    }
  }
}
</style>
<template>
  <section
    class="upload-oc"
    :style="{ height: (listType === 'picture' && '100%') || 'auto' }"
  >
    <div class="upload-box">
      <!-- 不使用oss上传文件 -->
      <el-upload
        v-if="!isUseOss"
        class="upload-demo"
        ref="UplaodFile"
        :class="{
          nborder: fileList.length && listType === 'picture',
        }"
        :action="uploadUrl"
        :data="uploadData"
        :before-upload="handleBeforeUpload"
        :on-success="handleSuccess"
        :on-error="handleUploadError"
        :on-remove="handleRemove"
        :on-exceed="handleExceed"
        :file-list="fileList"
        :multiple="upOption.fileLimit > 1"
        :headers="paramsData"
        :limit="upOption.fileLimit"
        :list-type="listType"
        :drag="listType === 'picture'"
        :disabled="disabled || loading"
        :show-file-list="showFileList"
      >
        <!-- 图片上传（单张） -->
        <template v-if="listType === 'picture'">
          <div class="img-single" v-loading="loading">
            <template v-if="!fileList.length">
              <i class="el-icon-upload" />
              <p class="el-upload__text">将图片拖到此处，或<em>点击上传</em></p>
            </template>
            <template v-else>
              <el-image
                :src="fileList[0].url"
                fit="cover"
                @load="loading = false"
                @error="loading = false"
              >
                <div slot="error" class="image-slot">
                  <font class="icon-tupianshibai" />
                </div>
              </el-image>
            </template>
          </div>
        </template>
        <!-- 文件上传 、图片上传（多张） -->
        <template v-if="listType === 'text' || listType === 'picture-card'">
          <div class="upload-control">
            <el-button size="small" type="primary" slot="trigger">
              {{ btntext }}
            </el-button>
            <div slot="tip" class="el-upload__tip" @click.stop>
              <p class="tip-text">
                <font class="icon-xinxi" />
                <slot name="suptip"></slot>支持上传{{
                  fileTypeName || "jpg/png"
                }}文件， 且不能超过 {{ upOption.fileSize }}MB
              </p>
            </div>
          </div>
          <div class="trigger-mask" @click.stop></div>
        </template>
      </el-upload>
      <!-- 使用oss上传文件 -->
      <el-upload
        v-else
        class="upload-demo"
        ref="uploadOss"
        :class="{
          nborder: fileList.length && listType === 'picture',
        }"
        :action="uploadUrl"
        :data="uploadData"
        :before-upload="handleBeforeUpload"
        :on-success="handleSuccess"
        :on-error="handleUploadError"
        :on-remove="handleOssRemove"
        :on-exceed="handleExceed"
        :file-list="fileList"
        :multiple="upOption.fileLimit > 1"
        :limit="upOption.fileLimit"
        :list-type="listType"
        :drag="listType === 'picture'"
        :disabled="disabled || loading"
        :style="{'cursor': (disabled || loading) ? 'default' : 'pointer'}"
        :http-request="handleUploadFile"
        :show-file-list="showFileList"
      >
        <!-- 图片上传（单张） -->
        <template v-if="listType === 'picture'">
          <div class="img-single" v-loading="loading" :style="{'cursor': (disabled || loading) ? 'default' : 'pointer'}">
            <template v-if="!fileList.length">
              <i class="el-icon-upload" />
              <p class="el-upload__text">将图片拖到此处，或<em>点击上传</em></p>
            </template>
            <template v-else>
              <el-image
                :src="fileList[0].url"
                fit="cover"
                @load="loading = false"
                @error="loading = false"
              >
                <div slot="error" class="image-slot">
                  <font class="icon-tupianshibai" />
                </div>
              </el-image>
            </template>
          </div>
        </template>
        <!-- 文件上传 、图片上传（多张） -->
        <template v-if="listType === 'text' || listType === 'picture-card'">
          <div class="upload-control">
            <el-button size="small" type="primary" slot="trigger">
              {{ btntext }}
            </el-button>
            <div slot="tip" class="el-upload__tip" @click.stop>
              <p class="tip-text">
                <font class="icon-xinxi" />
                <slot name="suptip"></slot>支持上传{{
                  fileTypeName || "jpg/png"
                }}文件， 且不能超过 {{ upOption.fileSize }}MB
              </p>
            </div>
          </div>
          <div class="trigger-mask" @click.stop></div>
        </template>
      </el-upload>

      <!-- 文件列表 -->
      <template
        v-if="
          !showFileList && (listType === 'text' || listType === 'picture-card')
        "
      >
        <ul class="file-list" v-if="exhibitFile.length">
          <li
            v-for="item in exhibitFile"
            :key="item.uid"
            :class="file_status(item)"
          >
            <span class="file-status"></span>
            <p>{{ item.name }}</p>
            <font class="icon-shanchu" @click="delExhibitFile(item)" />
          </li>
        </ul>
        <p v-if="file_loading" class="loading-text">
          {{ loadingText }}
        </p>
      </template>
      <!-- 单图上传文本提示 -->
      <div class="img-single-tips" v-if="showImgTips && listType === 'picture'">
        <font class="icon-xinxi" />
        <span>
          <slot name="suptip"></slot>格式{{ fileTypeName || "jpg/png" }}，
          大小不超过{{ upOption.fileSize }}MB
        </span>
      </div>
    </div>
    <el-dialog title="图片裁剪" :visible.sync="dialogVisible" :modal="false" width="504px"  :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
      <Vuecopper :option="option" @cropper-request="handleUploadFile" @cropper-closerequest="handleCropperClose" />
    </el-dialog>
  </section>
</template>
<script>
import { getGroupData } from "@api/DataGroup";
import Vuecopper from "@comp/UploadCropper/cropper.vue";

import {
  UPLOAD_IMG_URL,
  UPLOAD_FILE_URL,
  UPLOAD_FILE_SLICE,
  UPLOAD_FILE_INPORT,
} from "@config";

const OSS = require("ali-oss");
import { getaliyunoss } from "@api/common";
import { addAttachment } from "@api/Attachment";

export default {
  components: { Vuecopper },
  name: "TryUploadoc",
  props: {
    // 值
    value: [String, Object, Array],
    // 使用本地配置
    useLocalConfig: {
      type: Boolean,
      default: false,
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    // 文件类型, 例如["doc", "xls", "ppt", "txt", "pdf"]
    fileType: {
      type: Array,
      default: () => ["png", "jpg", "jpeg", "gif"],
    },
    uploadData: {
      type: Object,
      default: () => {},
    },
    //上传文件类型
    upType: {
      type: String,
      default: "IMG",
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true,
    },
    // 最大允许上传个数
    fileLimit: {
      type: Number,
      default: 9,
    },
    //是否为导入组件
    isImport: {
      type: Boolean,
      default: false,
    },
    disabled: {
      //禁用属性
      type: Boolean,
      default: false,
    },
    // 是否使用Oss直传
    fileExt: {
      //单独设置 仅允许的文件格式
      type: Array,
      default: () => [],
    },
    // 是否使用Oss直传
    isUseOss: {
      type: Boolean,
      default: false,
    },
    // 需要二次上传oss的文件类型
    putTwoOssFileType: {
      type: Array,
      default: () => ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "GIF"],
    },
    btntext: {
      type: String,
      default: "点击上传",
    },
    showFileList: {
      type: Boolean,
      default: true,
    },
    showImgTips: {
      type: Boolean,
      default: false,
    },
    showCropper:{
      type: Boolean,
      default: false,
    },
    cropperOption:{
      type: Object,
      default: () => ({
        outputSize: 1
      })
    },
  },
  data() {
    return {
      uploadUrl: UPLOAD_IMG_URL, // 上传的图片服务器地址
      paramsData: {
        accessToken: sessionStorage.getItem("token"),
        charset: "utf-8",
      }, // 上传携带的参数，看需求要不要
      fileList: [],
      tempFileList: [], // 因为 fileList为只读属性，所以用了一个中间变量来进行数据改变的交互。
      upOption: {
        fileSize: this.fileSize,
        fileType: this.fileType,
        fileLimit: this.fileLimit,
      },
      // 文件列表类型 text/picture/picture-card
      listType: "picture",

      ossUploadData: [], // oss上传文件
      waitupLength: 0, // 等待上传文件数量
      upImglength: 0,
      upImgOklength: 0,
      loading: false,
      loadingText: "上传中，请稍后...",
      exhibitFile: [], // 展示的文件列表

      dialogVisible:  false, // 裁剪框显示
      option: {
        img: 'https://img1.baidu.com/it/u=4101939559,1931437443&fm=253&fmt=auto&app=138&f=JPEG?w=1067&h=800',
        outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
        outputType: 'png',  //裁剪生成图片的格式（jpeg || png || webp）
        info: true,          //图片大小信息
        canScale: false,      //图片是否允许滚轮缩放
        autoCrop: true,      //是否默认生成截图框
        autoCropWidth: 930,  //默认生成截图框宽度
        autoCropHeight: 942, //默认生成截图框高度
        fixed: true,         //是否开启截图框宽高固定比例
        fixedNumber: [1.53, 1], //截图框的宽高比例
        full: true,         //false按原比例裁切图片，不失真
        fixedBox: true,      //固定截图框大小，不允许改变
        canMove: false,      //上传图片是否可以移动
        canMoveBox: true,    //截图框能否拖动
        original: true,     //上传图片按照原始比例渲染
        centerBox: true,    //截图框是否被限制在图片里面
        height: true,        //是否按照设备的dpr 输出等比例图片
        infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000,    //限制图片最大宽度和高度
        enlarge: 1,            //图片根据截图框输出比例倍数
        mode: '620px 628px',  //图片默认渲染方式
        name: '',
        ...this.cropperOption
      },
      // 是否已经裁剪
      cropperDlog: false,
    };
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        if (newVal != "" && newVal != undefined) {
          let imgs = JSON.parse(newVal);
          this.fileList = imgs.map((a, index) => {
            return {
              uid: a.att_id,
              name: a.att_title,
              url: a.att_url,
            };
          });
          this.ossUploadData = imgs.map((a, index) => {
            return {
              att_id: a.att_id,
              att_title: a.att_title,
              att_url: a.att_url,
            };
          });
          if (!this.exhibitFile.length) {
            this.exhibitFile = imgs.map((a) => {
              return {
                uid: a.att_id,
                name: a.att_title,
                url: a.att_url,
                status: "success",
                oss_status1: "success", // 第1个oss上传状态
                oss_status2: "success", // 第2个oss上传状态
              };
            });
          }
        } else this.fileList = [];
      },
      immediate: true,
      deep: true,
    },
    fileExt: {
      handler: function (newVal, oldVal) {
        this.fileExt = newVal;
        if (this.fileExt.length > 0) {
          this.upOption.fileType = this.fileExt;
        }
      },
    },
    upType: {
      handler: function (newVal, oldVal) {
        this.upType = newVal;

        this.upOption.fileLimit = this.upType == "FILE" ? 1 : this.fileLimit;

        //从多文件 切回单文件， 仅保留  最新的一个文件
        if (this.upOption.fileLimit == 1 && this.ossUploadData.length > 1) {
          this.ossUploadData = [
            this.ossUploadData[this.ossUploadData.length - 1],
          ];

          // 回传回 调用组件处
          const tempfileData = JSON.stringify(this.ossUploadData);
          this.$emit(
            "input",
            this.ossUploadData.length > 0 ? tempfileData : null
          );
        }
        console.log(222222222222222);
        console.log(this.upType);
        console.log(this.fileLimit);
        console.log(this.upOption.fileLimit);
        console.log(this.ossUploadData);
      },
    },
  },
  computed: {
    // 是否显示提示
    showTip() {
      return (
        this.isShowTip && (this.upOption.fileType || this.upOption.fileSize)
      );
    },
    fileTypeName() {
      let typeName = [];
      this.upOption.fileType.forEach((item) => {
        typeName.push(item.toLowerCase()); // 文本转小写
      });
      typeName = [...new Set(typeName)]; // 去重
      return typeName.join(",");
    },
    fileAccept() {
      let fileAccept = "";
      this.upOption.fileType.forEach((element) => {
        fileAccept += `.${element},`;
      });
      return fileAccept;
    },
    file_loading() {
      let file_loading = this.exhibitFile.length;
      this.exhibitFile.map((item) => {
        if (this.isUseOss) {
          if (item.oss_status1 != "loading" && item.oss_status2 != "loading") {
            file_loading--;
          }
        } else {
          if (item.status != "loading") {
            file_loading--;
          }
        }
      });
      return file_loading > 0;
    },
    file_status() {
      return (data) => {
        if (this.isUseOss) {
          if (data.oss_status1 == "loading" || data.oss_status2 == "loading") {
            return "loading";
          } else if (data.oss_status1 == "fail" || data.oss_status2 == "fail") {
            return "fail";
          } else {
            return "success";
          }
        } else return data.status;
      };
    },
  },
  created() {
    this.initData();
  },
  methods: {
    handleCropperClose() {
      this.dialogVisible = false;
      this.upImglength--
    },
    handleClose(done) {
      this.upImglength--
      done()
    },
    /*初始化数据*/
    async initData() {
      console.log(this.upOption)
      if (this.isImport) {
        this.upOption.fileLimit =
          this.upType == "FILE" ? 1 : this.upOption.fileLimit;
        this.uploadUrl = UPLOAD_FILE_INPORT;
        this.upOption = {
          fileSize: 5,
          fileType: ["xls", "xlsx"],
          fileLimit: 1,
        };
        this.listType = "text";
      } else if(!this.useLocalConfig) {
        console.log(this.upType);
        if (this.upType == "IMG" || this.upType == "IMGARR") {
          let { data: resImg } = await getGroupData("IMAGECONFIG");
          console.log(this.fileSize);
          this.upOption = resImg;
          console.log(resImg);
          this.upOption.fileLimit =
            this.upType == "IMG" ? 1 : this.upOption.fileLimit;
          this.listType = this.upType == "IMG" ? "picture" : "picture-card";
        } else {
          let { data: resFile } = await getGroupData("FILECONFIG");
          this.upOption = resFile;
          if (this.fileExt.length > 0) {
            this.upOption.fileType = this.fileExt;
          }
          this.upOption.fileLimit =
            this.upType == "FILE" ? 1 : this.upOption.fileLimit;
          this.uploadUrl = UPLOAD_FILE_URL;
          this.listType = "text";
        }
      }
      console.log(this.useLocalConfig)
    },

    // OSS自定义上传
    async handleUploadFile(option) {
      this.loading = true;
      // if (!this.loading) {
        // this.loading = true;
      // }
      this.upImglength++;
      if (this.handleBeforeUpload(option.file)) {
        //获取上传后的url
        const _name =
          this.getDate() +
          "/" +
          this.generateCustomKey() +
          "." +
          option.file.name.split(".")[option.file.name.split(".").length - 1];
        /** 上传第二个oss */
        // 提取文件扩展名
        let fileExtension = option.file.name.slice(
          option.file.name.lastIndexOf(".") + 1
        );
        // 检查文件类型是否符合要求
        const isTypeOk = this.putTwoOssFileType.some((type) => {
          // 判断文件扩展名是否包含允许的类型之一
          if (fileExtension.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
        this.exhibitFile.push({
          uid: option.file.uid,
          name: option.file.name,
          status: "loading",
          oss_status1: "loading", // 第1个oss上传状态
          oss_status2: (isTypeOk && "loading") || "success", // 第2个oss上传状态
        });
        let cur_file_index;
        this.exhibitFile.map((item, index) => {
          if (item.uid == option.file.uid) {
            cur_file_index = index;
          }
        });
        // 如果文件类型符合要求且文件存在，则二次上传文件
        if (isTypeOk && option.file) {
          await this.putTwoOss(_name, option.file)
            .then(() => {
              this.exhibitFile[cur_file_index].oss_status2 = "success";
            })
            .catch((err) => {
              this.exhibitFile[cur_file_index].oss_status2 = "fail";
              console.log("putTwoOss", err);
            });
        }
        /** 上传第一个oss */
        await this.putOss(_name, option.file)
          .then(async (res) => {
            if (res) {
              this.getOssUrl(res.name).then(async (rel) => {
                const url = new URL(rel);
                const requestUrls = url.origin + url.pathname;
                let tempItem = {
                  att_title: option.file.name,
                  att_url: requestUrls,
                };
                /** 获取入库文件attid */
                let att_data = await this.addAttachment(tempItem);
                if (att_data) {
                  tempItem.att_id = att_data.att_id;
                  tempItem.att_url = att_data.att_url;
                  this.upImgOklength++;
                  if (this.upOption.fileLimit > 1) {
                    //多文件
                    this.ossUploadData.push(tempItem);
                  } else {
                    //单文件
                    this.ossUploadData = [tempItem];
                  }
                  if (this.upImglength == this.upImgOklength) {
                    const tempfileData = JSON.stringify(this.ossUploadData);
                    this.loading = false
                    this.$nextTick(() => {
                      this.$emit(
                        "input",
                        this.ossUploadData.length > 0 ? tempfileData : null
                      );
                    })
                    
                  }
                  this.exhibitFile[cur_file_index].oss_status1 = "success";
                  this.exhibitFile[cur_file_index].uid = tempItem.att_id;
                } else {
                  this.exhibitFile[cur_file_index].oss_status1 = "fail";
                }
              });
            } else {
              this.$message.error("前端直传OSS失败");
              return false;
            }
          })
          .catch((err) => {
            this.exhibitFile[cur_file_index].oss_status1 = "fail";
            console.log("putOss", err);
          });
      }
    },
    /** 上传第二个oss */
    async putTwoOss(filename, file) {
      try {
        let res = await getaliyunoss();
        let ossConfig = res.data;
        const client = new OSS({
          region: ossConfig.region2 ? ossConfig.region2 : "",
          accessKeyId: ossConfig.accessKeyId,
          accessKeySecret: ossConfig.accessKeySecret,
          stsToken: ossConfig.securityToken,
          bucket: ossConfig.bucket2 ? ossConfig.bucket2 : "", // 第二个oss的bucket自行替换
        });
        let fileurl = ossConfig.img_path + filename;
        const headers = {
          // 指定该Object被下载时的内容编码格式。
          "Content-Encoding": "utf-8",
          "Access-Control-Allow-Origin": "*",
        };
        const options = {
          headers: headers,
          // 获取分片上传进度、断点和返回值。
          progress: (p, cpt, res) => {
            console.log(p);
            // this.loadingText = (p * 100).toFixed(0) + "%";
          },
          // 设置并发上传的分片数量。
          parallel: 4,
          // 设置分片大小。默认值为1 MB，最小值为100 KB。
          partSize: 1024 * 1024,
          // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
          meta: { year: 2020, people: "test" },
          //mime: "text/plain",
          timeout: 600000, //设置超时时间
        };
        const result = await client.multipartUpload(fileurl, file, options);
        console.log("putTwoOss", result);
        // return true;
        return result;
      } catch (e) {
        return e;
      }
    },
    /** 文件入附件库 */
    async addAttachment(data) {
      data.att_storage = 2;
      const { data: resdata } = await addAttachment(data);
      return resdata;
    },
    /** 提交数据到oss获取oss返回数据 */
    async putOss(fileName, file) {
      try {
        let res = await getaliyunoss();
        let ossConfig = res.data;
        const client = new OSS({
          // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
          region: ossConfig.region,
          accessKeyId: ossConfig.accessKeyId,
          accessKeySecret: ossConfig.accessKeySecret,
          stsToken: ossConfig.securityToken,
          bucket: ossConfig.bucket,
          // timeout: 600000,
          // secure: false,
          // refreshSTSTokenInterval: 3000000,
          // refreshSTSToken: getaliyunoss().then(res => {
          //   if (res.status === 200) {
          //     // console.log('成功刷新oss token',res.data);
          //     ossConfig.accessKeyId = res.data.accessKeyId;
          //     ossConfig.accessKeySecret = res.data.accessKeySecret;
          //     ossConfig.securityToken = res.data.securityToken;
          //   }
          // })
        });
        let fileurl = ossConfig.img_path + fileName;
        const headers = {
          // 指定该Object被下载时的网页缓存行为。
          // "Cache-Control": "no-cache",
          // 指定该Object被下载时的名称。
          // "Content-Disposition": "example.txt",
          // 指定该Object被下载时的内容编码格式。
          "Content-Encoding": "utf-8",
          // 指定过期时间，单位为毫秒。
          // Expires: "1000",
          // 指定Object的存储类型。
          // "x-oss-storage-class": "Standard",
          // 指定Object标签，可同时设置多个标签。
          // "x-oss-tagging": "Tag1=1&Tag2=2",
          // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
          // "x-oss-forbid-overwrite": "true",

          "Access-Control-Allow-Origin": "*",
        };
        const options = {
          headers: headers,
          // 获取分片上传进度、断点和返回值。
          progress: (p, cpt, res) => {
            // console.log(p);
            // this.loadingText =
            //   "上传中 " + (p * 100).toFixed(0) + "%，请稍后...";
          },
          // 设置并发上传的分片数量。
          parallel: 4,
          // 设置分片大小。默认值为1 MB，最小值为100 KB。
          partSize: 1024 * 1024,
          // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
          meta: { year: 2020, people: "test" },
          // mime: "text/plain",
          timeout: 600000, //设置超时时间
        };
        const result = await client.multipartUpload(fileurl, file, options);
        console.log("putOss", result);
        return result;
      } catch (e) {
        return e;
      }
    },
    async getOssUrl(name) {
      try {
        let res = await getaliyunoss();
        let ossConfig = res.data;
        const client = new OSS({
          region: ossConfig.region,
          accessKeyId: ossConfig.accessKeyId,
          accessKeySecret: ossConfig.accessKeySecret,
          stsToken: ossConfig.securityToken,
          bucket: ossConfig.bucket,
        });
        const result = await client.signatureUrl(name);
        return result;
      } catch (e) {
        console.log(e);
      }
    },

    // 文件列表移除文件时的钩子
    handleOssRemove(file, fileList) {
      this.changeOssFileList(fileList);
    },
    // 文件列表改变的时候，更新组件的v-model的数据
    changeOssFileList(fileList) {
      let tempFileList = [];
      fileList.map((item) => {
        let tempItem = {};
        this.ossUploadData.map((val) => {
          if (item.uid == val.att_id) {
            tempItem = {
              att_id: item.uid,
              att_title: item.name,
              att_url: val.att_url,
            };
            tempFileList.push(tempItem);
          }
        });
      });
      this.ossUploadData = tempFileList;
      this.fileList = [...fileList];
      const tempfileData = JSON.stringify(this.ossUploadData);
      this.$emit("input", this.ossUploadData.length > 0 ? tempfileData : null);
    },

    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      this.loading = true;
      // 校检文件类型
      if (this.upOption.fileType && file) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = this.upOption.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });

        if (!isTypeOk && file) {
          this.$message.error(
            `文件格式不正确, 请上传${this.upOption.fileType.join("/")}格式文件!`
          );
          return this.loading = false;
        }
      }
      // 校检文件大小
      if (this.upOption.fileSize && file) {
        const isLt = file.size / 1024 / 1024 < this.upOption.fileSize;
        if (!isLt) {
          this.$message.error(
            `上传文件大小不能超过 ${this.upOption.fileSize} MB!`
          );
          return this.loading = false;
        }
      }
      if (!this.isUseOss) {
        this.exhibitFile.push({
          uid: file.uid,
          name: file.name,
          status: "loading",
        });
      }

      // 如果需要裁剪
      if (this.showCropper) {
        this.cropperDlog = !this.cropperDlog;
        if (this.cropperDlog) {
          this.loading = false
          // 读取文件并显示在裁剪区域
          const imageUrl = URL.createObjectURL(file)

          this.option.name = file.name;

          this.option.img = imageUrl;

          this.dialogVisible = true;
          return false
        }
        this.dialogVisible = false;
      }
      return true;
    },
    handleUploadError(err, file) {
      this.$message.error("上传失败, 请重试");
      this.loading = false;
      console.log(err);
      let cur_file_index;
      this.exhibitFile.map((item, index) => {
        if (file.uid == item.uid) {
          cur_file_index = index;
        }
      });
      this.exhibitFile[cur_file_index].status = "fail";
    },
    // 文件个数超出
    handleExceed(files, fileList) {
      this.loading = false;
      if (this.upOption.fileLimit == 1) {
        if (!this.isUseOss) {
          this.$refs.UplaodFile.clearFiles(); //删除所有上传的文件
          this.$refs.UplaodFile.handleStart(files[0]); //handleStart()指的是手动选择文件
          this.$refs.UplaodFile.submit(); //手动上传
        } else {
          this.$refs.uploadOss.clearFiles(); //删除所有上传的文件
          this.$refs.uploadOss.handleStart(files[0]); //handleStart()指的是手动选择文件
          this.$refs.uploadOss.submit(); //手动上传
        }
      } else {
        this.$message.error(
          `超出上传文件个数限制，单次上传限制：` +
            this.upOption.fileLimit +
            "个文件以内！"
        );
      }
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      this.changeFileList(fileList);
    },
    // 文件列表改变的时候，更新组件的v-model的数据
    changeFileList(fileList) {
      const tempFileList = fileList.map((item) => {
        let tempItem = {};
        if (item.response && item.response.status === 200) {
          tempItem = {
            att_id: item.response.data.att_id,
            att_title: item.response.data.original_name,
            att_url: item.response.data.address,
          };
        } else {
          tempItem = {
            att_id: item.uid,
            att_title: item.name,
            att_url: item.url,
          };
        }
        return tempItem;
      });
      const tempfileData = JSON.stringify(tempFileList);
      // console.log("tempfileData",tempfileData);
      this.$emit("input", tempFileList.length > 0 ? tempfileData : null);
    },

    handleSuccess(res, file, fileList) {
      let cur_file_index;
      this.exhibitFile.map((item, index) => {
        if (file.uid == item.uid) {
          cur_file_index = index;
        }
      });
      // 图片上传服务器后的回调
      if (res) {
        if (res.status === 200) {
          let index = 0;
          fileList.map((item) => {
            if (
              (item.response && item.response.status === 200) ||
              item.status == "success"
            ) {
              index++;
            }
          });
          if (fileList.length == index) {
            this.changeFileList(fileList);
          }
          this.exhibitFile[cur_file_index].status = "success";
          this.exhibitFile[cur_file_index].uid = res.data.att_id;
          this.exhibitFile[cur_file_index].name = res.data.original_name;
          this.exhibitFile[cur_file_index].url = res.data.address;
        } else {
          if (this.listType !== "text") {
            this.delShowFile(file, fileList);
            this.$message.error(file.name + "上传失败!");
          } else {
            this.exhibitFile[cur_file_index].status = "fail";
          }
        }
      }
    },

    // 把上传失败的从list中删除
    delShowFile(file, fileList) {
      let index = fileList.findIndex((item) => {
        return item.name === file.name;
      });
      if (index !== -1) {
        fileList.splice(index, 1);
      }
    },

    // 获取当前年月日
    getDate() {
      const date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      month = month > 9 ? month : "0" + month;
      day = day < 10 ? "0" + day : day;
      var today = year + month + day;
      return today;
    },
    /**
     * 根据时间生成唯一键
     */
    generateCustomKey() {
      const datePart = new Date()
        .toISOString()
        .replace(/[-:.TZ]/g, "")
        .slice(0, 14); // 获取当前时间的特定格式
      const randomPart = Math.random().toString(36).substring(2, 10); // 生成随机字符串
      return datePart + randomPart;
    },

    /**
     * 自定义文件列表文件删除
     * @param file 删除的文件
     */
    delExhibitFile(file) {
      let cur_file_index;
      this.exhibitFile.map((item, index) => {
        if (file.uid == item.uid) {
          cur_file_index = index;
        }
      });
      this.exhibitFile.splice(cur_file_index, 1);
      if (this.isUseOss) {
        this.handleOssRemove(file, this.exhibitFile);
      } else {
        this.handleRemove(file, this.exhibitFile);
      }
    },
  },
};
</script>
